import { main } from '@/common/store-function'
const contextPath = '/v1/main/information'

/** STORE STATE */
export const state = () => ({
  informationList: []
})

/** GETTERS */
export const getters = {
  informationList: state => state.informationList
}

/** ACTIONS */
export const actions = {
  /** 初期化 */
  async initialize({ commit }) {
    await commit('ResetStore')
  },
  /** FETCH：お知らせ情報取得 */
  async fetchInformationList({ commit }) {
    await this.$axios.$get(contextPath).then(response => {
      commit(main.COMMIT.SET_INFORMATION_LIST, response)
    })
  }
}

/** MUTATIONS */
export const mutations = {
  /** 変換：お知らせ情報 */
  SetInformationList(state, items) {
    state.informationList = items
  },
  /** 初期化 */
  ResetStore(state) {
    state.informationList = []
  }
}
