import Vue from 'vue'
import moment from 'moment'

/** フォーマット：日付データ */
Vue.prototype.$momentFormat = dateTime => {
  return moment(dateTime).isValid() ? moment(dateTime).format('YYYY/MM/DD') : dateTime
}

/** フォーマット：タイムスタンプ */
Vue.prototype.$momentFormatTimestamp = dateTime => {
  return moment(dateTime).isValid() ? moment(dateTime).format('YYYY/MM/DD HH:mm') : dateTime
}

/** フォーマット：タイムスタンプ(HM) */
Vue.prototype.$momentFormatTimestampHM = dateTime => {
  return moment(dateTime).isValid() ? moment(dateTime).format('HH:mm') : dateTime
}

/** 取得：現在日付 */
Vue.prototype.$getToday = () => {
  return moment().format('YYYY-MM-DD')
}

/** 取得：現在日付 + 引数日数 */
Vue.prototype.$getTodayAfter = date => {
  return moment()
    .add(date, 'days')
    .format('YYYY-MM-DD')
}

/** 検証：Nullチェック */
Vue.prototype.$checkEmpty = (value, message) => {
  if (!value) {
    event.preventDefault()
    Vue.toasted.error(message)
    return false
  }
  return true
}

/** フォーマット：車番 */
Vue.prototype.$maskVehicleNo = value => {
  if (!value) return value
  return value.replace(/([0-9a-zA-Z])(?=([0-9a-zA-Z]{2})+$)/g, '$1-')
}

/** 取得：選択項目変更処理 「すべて」追加 */
Vue.prototype.$setSelectAll = list => {
  const newList = [...list]
  newList.unshift({ value: null, label: 'すべて' })
  return newList
}

/** 取得：受付情報詳細Path */
Vue.prototype.$createDetailPath = args => {
  return { path: `${args.basePath}/detail`, query: { id: `${args.id}` } }
}

/** 取得：受付情報詳細Path */
Vue.prototype.$createDetailEditPath = args => {
  return { path: `${args.basePath}/detail/edit`, query: { id: `${args.id}` } }
}

/** 取得： 受付情報詳細ID */
Vue.prototype.$getDetailId = arg => {
  return arg.query
}
