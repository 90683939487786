<template lang="pug">
  v-text-field.text-dash.pt-4(v-model="inputData" prepend-inner-icon="mdi-cellphone" :label="formLabel"
  :readonly="isReadOnly" :disabled="isDisable" hide-details v-mask="mask")
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: () => '',
      required: false
    },
    formLabel: {
      type: String,
      required: true
    },
    isReadOnly: {
      type: Boolean,
      default: () => false,
      requied: false
    },
    isDisable: {
      type: Boolean,
      default: () => false,
      requied: false
    }
  },
  data: () => ({
    mask: '###-####-####'
  }),
  computed: {
    inputData: {
      get() {
        return this.value.replace(/(\d)(?=(\d{4})+$)/g, '$1-')
      },
      set(changeValue) {
        if (this.value !== changeValue) this.$emit('input', changeValue)
      }
    }
  }
}
</script>
