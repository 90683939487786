import { settingCenter } from '@/common/store-function'
const contextPath = '/v1/setting/center'

/** STORE STATE */
export const state = () => ({
  centerInfo: {
    id: Number,
    name: String,
    code: String,
    plantCode: String,
    pickupCode: String,
    displayOrder: Number,
    updatedAt: String
  },
  centerList: []
})

/** GETTERS */
export const getters = {
  // センター情報 1件
  centerInfo: state => state.centerInfo,
  // センター情報リスト
  centerList: state => state.centerList
}

/** ACTIONS */
export const actions = {
  /** 初期化 */
  async initialize({ commit }) {
    await commit('ResetStore')
  },
  /** FETCH：センター情報 一覧取得 */
  async fetchList({ commit }) {
    await this.$axios.$get(contextPath).then(response => {
      commit(settingCenter.COMMIT.SET_LIST, response)
    })
  },
  /** FETCH：センター情報 1件取得 */
  fetchInfo({ commit }, info) {
    commit(settingCenter.COMMIT.SET_INFO, info)
  },
  /** GET：センター情報 1件取得 */
  async getInfoById({ commit }, info) {
    const result = await this.$axios.$get(`${contextPath}/${info.id}`)
    return result
  },
  /** POST：センター情報 新規登録 */
  async postInfo({ dispatch, commit, state }, info) {
    // 登録失敗を考え、一旦Vuexにコミット
    commit(settingCenter.COMMIT.SET_INFO, info)
    await this.$axios.$post(contextPath, state.centerInfo).then(() => {
      dispatch(settingCenter.ACTIONS.FETCH_LIST)
    })
  },
  /** PUT：センター情報 1件更新 */
  async putInfoById({ dispatch, commit, state }, info) {
    // 登録失敗を考え、一旦Vuexにコミット
    commit(settingCenter.COMMIT.SET_INFO, info)
    await this.$axios.$put(`${contextPath}/${state.centerInfo.id}`, state.centerInfo).then(() => {
      dispatch(settingCenter.ACTIONS.FETCH_LIST)
    })
  }
}
/** MUTATIONS */
export const mutations = {
  /** 設定：センター情報 1件 */
  SetInfo(state, info) {
    state.centerInfo.id = info.id
    state.centerInfo.name = info.name
    state.centerInfo.code = info.code
    state.centerInfo.plantCode = info.plantCode
    state.centerInfo.pickupCode = info.pickupCode
    state.centerInfo.displayOrder = info.displayOrder
    state.centerInfo.updatedAt = info.updatedAt
  },
  /** 設定：センター情報リスト */
  SetList(state, items) {
    state.centerList = items
  },
  /** 初期化 */
  ResetStore(state) {
    state.centerInfo = {}
    state.centerList = []
  }
}
