import { settingRole } from '@/common/store-function'
const contextPath = '/v1/setting/role'

/** STORE STATE */
export const state = () => ({
  roleInfo: {
    id: Number,
    name: String,
    toAuthorization: [
      {
        authorizationId: Number
      }
    ],
    updatedAt: String
  },
  roleList: []
})

/** GETTERS */
export const getters = {
  // 権限情報 1件
  roleInfo: state => state.roleInfo,
  // 権限情報リスト
  roleList: state => state.roleList
}

/** ACTIONS */
export const actions = {
  /** 初期化 */
  async initialize({ commit }) {
    await commit('ResetStore')
  },
  /** FETCH：権限情報 一覧取得 */
  async fetchList({ commit }) {
    await this.$axios.$get(contextPath).then(response => {
      commit(settingRole.COMMIT.SET_LIST, response)
    })
  },
  /** FETCH：権限情報 1件取得 */
  fetchInfo({ commit }, info) {
    commit(settingRole.COMMIT.SET_INFO, info)
  },
  /** GET：権限情報 1件取得 */
  async getInfoById({ commit }, info) {
    const result = await this.$axios.$get(`${contextPath}/${info.id}/authorization`)
    return result
  },
  /** POST：権限情報 新規登録 */
  async postInfo({ dispatch, commit, state }, info) {
    // 登録失敗を考え、一旦Vuexにコミット
    commit(settingRole.COMMIT.SET_INFO, info)
    await this.$axios.$post(`${contextPath}/authorization`, state.roleInfo).then(() => {
      dispatch(settingRole.ACTIONS.FETCH_LIST)
    })
  },
  /** PUT：認可情報 1件更新 */
  async putInfoById({ dispatch, commit, state }, info) {
    // 登録失敗を考え、一旦Vuexにコミット
    commit(settingRole.COMMIT.SET_INFO, info)
    await this.$axios.$put(`${contextPath}/${state.roleInfo.id}/authorization`, state.roleInfo).then(() => {
      dispatch(settingRole.ACTIONS.FETCH_LIST)
    })
  }
}

/** MUTATIONS */
export const mutations = {
  /** 設定：権限情報 1件 */
  SetInfo(state, info) {
    state.roleInfo.id = info.id
    state.roleInfo.name = info.name
    state.roleInfo.toAuthorization = info.toAuthorization
    state.roleInfo.updatedAt = info.updatedAt
  },
  /** 設定：権限情報リスト */
  SetList(state, items) {
    state.roleList = items
  },
  /** 初期化 */
  ResetStore(state) {
    state.roleInfo = {}
    state.roleList = []
  }
}
