//※input type='date'の読み取り専用に関して
//通常のreadonlyではモバイルブラウザ(Safari,Chrome)標準のdatepickerが無効にならないのでtypeをtextにしたものと置き換える
//disableだと文字色まで変わってしまい仕様の読み取り専用の形と異なってしまうので上記で対応
//モバイルブラウザ(Safari,Chrome)標準のdatepickerについては右記にて確認→ttps://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date
<template lang="pug">
  v-text-field.text-dash.pt-4.date-field(v-if="!isReadOnly" v-model="inputDate" type="date" :label="formLabel" max="9999-12-31"
  prepend-inner-icon="mdi-calendar" :readonly="isReadOnly" :disabled="isDisable" :rules="rules" hide-details)
  v-text-field.text-dash.pt-4.date-field(v-else="!isReadOnly" :value="this.$momentFormat(inputDate)" type="text" :label="formLabel" max="9999-12-31"
  prepend-inner-icon="mdi-calendar" :readonly="isReadOnly" :disabled="isDisable" :rules="rules" hide-details)
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    formLabel: {
      type: String,
      required: true
    },
    isReadOnly: {
      type: Boolean,
      default: () => false,
      required: false
    },
    isDisable: {
      type: Boolean,
      default: () => false,
      required: false
    },
    rules: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  computed: {
    inputDate: {
      get() {
        return this.value
      },
      set(newValue) {
        if (this.value !== newValue) this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.date-field
  /** クリアボタン */
  ::-webkit-clear-button
    -webkit-appearance: none
  ::-webkit-inner-spin-button
    -webkit-appearance: none
</style>
