<template lang="pug">
  div(v-if="value") 表示
  div(v-else) 非表示
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      requied: false
    }
  }
}
</script>
