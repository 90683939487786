<template lang="pug">
  v-app
    v-content
      v-container(fill-height)
        v-row(no-gutters align="center" justify="center")
          v-card.px-4.py-3
            v-card-title
              v-row(no-gutters align="center" justify="center")
                img(alt="logo" src="@/assets/logo/main.png")
            v-card-text.pt-4.headline.error-message {{ $message.error_404 }}
            v-card-actions
              v-btn(block color="primary" dark to="/" nuxt) ログイン画面に戻る
</template>

<script>
export default {
  layout() {
    return 'default'
  },
  middleware: 'no-authorization'
}
</script>

<style lang="sass" scoped>
.error-message
  white-space: pre-wrap
</style>
