import { settingInformation } from '@/common/store-function'
const contextPath = '/v1/setting/information'

/** STORE STATE */
export const state = () => ({
  informationInfo: {
    id: Number,
    title: String,
    body: String,
    releaseStartDate: Date,
    releaseEndDate: Date,
    isDisplay: Boolean,
    updatedAt: String
  },
  informationList: []
})

/** GETTERS */
export const getters = {
  // お知らせ情報 1件
  informationInfo: state => state.informationInfo,
  // お知らせ情報リスト
  informationList: state => state.informationList
}

/** ACTIONS */
export const actions = {
  /** 初期化 */
  async initialize({ commit }) {
    await commit('ResetStore')
  },
  /** FETCH：お知らせ情報 一覧取得 */
  async fetchList({ commit }) {
    await this.$axios.$get(contextPath).then(response => {
      commit(settingInformation.COMMIT.SET_LIST, response)
    })
  },
  /** FETCH：お知らせ情報 1件取得 */
  fetchInfo({ commit }, info) {
    commit(settingInformation.COMMIT.SET_INFO, info)
  },
  /** GET：お知らせ情報 1件取得 */
  async getInfoById({ commit }, info) {
    const result = await this.$axios.$get(`${contextPath}/${info.id}`)
    return result
  },
  /** POST：お知らせ情報 新規登録 */
  async postInfo({ dispatch, commit, state }, info) {
    // 登録失敗を考え、一旦Vuexにコミット
    commit(settingInformation.COMMIT.SET_INFO, info)
    await this.$axios.$post(contextPath, state.informationInfo).then(() => {
      dispatch(settingInformation.ACTIONS.FETCH_LIST)
    })
  },
  /** PUT：お知らせ情報 1件更新 */
  async putInfoById({ dispatch, commit, state }, info) {
    // 登録失敗を考え、一旦Vuexにコミット
    commit(settingInformation.COMMIT.SET_INFO, info)
    await this.$axios.$put(`${contextPath}/${state.informationInfo.id}`, state.informationInfo).then(() => {
      dispatch(settingInformation.ACTIONS.FETCH_LIST)
    })
  }
}
/** MUTATIONS */
export const mutations = {
  /** 設定：お知らせ情報 1件 */
  SetInfo(state, info) {
    state.informationInfo.id = info.id
    state.informationInfo.title = info.title
    state.informationInfo.body = info.body
    state.informationInfo.releaseStartDate = info.releaseStartDate
    state.informationInfo.releaseEndDate = info.releaseEndDate
    state.informationInfo.isDisplay = info.isDisplay
    state.informationInfo.updatedAt = info.updatedAt
  },
  /** 設定：お知らせ情報リスト */
  SetList(state, items) {
    state.informationList = items
  },
  /** 初期化 */
  ResetStore(state) {
    state.informationInfo = {}
    state.informationList = []
  }
}
