<template lang="pug">
  v-btn.custom-button(tile outlined color="red" @click="onClick")
    v-icon(left) mdi-close-circle
    slot 削除
</template>

<script>
export default {
  props: {},
  methods: {
    onClick() {
      this.$emit('clickDelete')
    }
  }
}
</script>

<style lang="sass" scoped>
.custom-button
  padding: 0 16px !important
</style>
