<template lang="pug">
  v-list-group(v-model="isActive" :prepend-icon="menuGroup.icon" no-action active-class="green--text")
    template(v-slot:activator)
      v-list-item-title {{ menuGroup.title }}
    v-list-item(v-for="(menu, i) in menuGroup.menu" :key="i" :to="menu.toContext")
      v-list-item-title(v-text="menu.linkName")
</template>

<script>
import { mapGetters } from 'vuex'
import { initialize } from '@/common/store-function'

export default {
  props: {
    /** PROPS：サイドメニュー情報 */
    menuGroup: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    active: false
  }),
  computed: {
    /** GET：Vuex */
    ...mapGetters({
      mini: initialize.GETTERS.IS_MINI
    }),
    isActive: {
      get() {
        return this.mini === true ? false : this.active
      },
      set(value) {
        this.active = this.mini === true ? false : value
      }
    }
  }
}
</script>
