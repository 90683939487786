import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _386d9204 = () => interopDefault(import('../src/pages/404.vue' /* webpackChunkName: "pages/404" */))
const _7ed0ebd5 = () => interopDefault(import('../src/pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _8612c868 = () => interopDefault(import('../src/pages/berth/index.vue' /* webpackChunkName: "pages/berth/index" */))
const _c8981ab4 = () => interopDefault(import('../src/pages/main/index.vue' /* webpackChunkName: "pages/main/index" */))
const _0ca0b994 = () => interopDefault(import('../src/pages/arrival/add/index.vue' /* webpackChunkName: "pages/arrival/add/index" */))
const _78c6f892 = () => interopDefault(import('../src/pages/arrival/export/index.vue' /* webpackChunkName: "pages/arrival/export/index" */))
const _6d766a68 = () => interopDefault(import('../src/pages/arrival/import/index.vue' /* webpackChunkName: "pages/arrival/import/index" */))
const _0de843be = () => interopDefault(import('../src/pages/arrival/list/index.vue' /* webpackChunkName: "pages/arrival/list/index" */))
const _bcbfae4c = () => interopDefault(import('../src/pages/arrival/schedule/index.vue' /* webpackChunkName: "pages/arrival/schedule/index" */))
const _e09216aa = () => interopDefault(import('../src/pages/arrival/search/index.vue' /* webpackChunkName: "pages/arrival/search/index" */))
const _3c89b80d = () => interopDefault(import('../src/pages/setting/berth/index.vue' /* webpackChunkName: "pages/setting/berth/index" */))
const _0eff95a1 = () => interopDefault(import('../src/pages/setting/center/index.vue' /* webpackChunkName: "pages/setting/center/index" */))
const _ca762914 = () => interopDefault(import('../src/pages/setting/information/index.vue' /* webpackChunkName: "pages/setting/information/index" */))
const _18a5bcc2 = () => interopDefault(import('../src/pages/setting/role/index.vue' /* webpackChunkName: "pages/setting/role/index" */))
const _5be67c52 = () => interopDefault(import('../src/pages/setting/user/index.vue' /* webpackChunkName: "pages/setting/user/index" */))
const _3d118695 = () => interopDefault(import('../src/pages/arrival/list/detail/index.vue' /* webpackChunkName: "pages/arrival/list/detail/index" */))
const _3943e0cb = () => interopDefault(import('../src/pages/arrival/search/detail/index.vue' /* webpackChunkName: "pages/arrival/search/detail/index" */))
const _ea39bcc2 = () => interopDefault(import('../src/pages/plan/list/edit.vue' /* webpackChunkName: "pages/plan/list/edit" */))
const _a57613d2 = () => interopDefault(import('../src/pages/arrival/list/detail/edit.vue' /* webpackChunkName: "pages/arrival/list/detail/edit" */))
const _3a0d2618 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _386d9204,
    name: "404"
  }, {
    path: "/auth",
    component: _7ed0ebd5,
    name: "auth"
  }, {
    path: "/berth",
    component: _8612c868,
    name: "berth"
  }, {
    path: "/main",
    component: _c8981ab4,
    name: "main"
  }, {
    path: "/arrival/add",
    component: _0ca0b994,
    name: "arrival-add"
  }, {
    path: "/arrival/export",
    component: _78c6f892,
    name: "arrival-export"
  }, {
    path: "/arrival/import",
    component: _6d766a68,
    name: "arrival-import"
  }, {
    path: "/arrival/list",
    component: _0de843be,
    name: "arrival-list"
  }, {
    path: "/arrival/schedule",
    component: _bcbfae4c,
    name: "arrival-schedule"
  }, {
    path: "/arrival/search",
    component: _e09216aa,
    name: "arrival-search"
  }, {
    path: "/setting/berth",
    component: _3c89b80d,
    name: "setting-berth"
  }, {
    path: "/setting/center",
    component: _0eff95a1,
    name: "setting-center"
  }, {
    path: "/setting/information",
    component: _ca762914,
    name: "setting-information"
  }, {
    path: "/setting/role",
    component: _18a5bcc2,
    name: "setting-role"
  }, {
    path: "/setting/user",
    component: _5be67c52,
    name: "setting-user"
  }, {
    path: "/arrival/list/detail",
    component: _3d118695,
    name: "arrival-list-detail"
  }, {
    path: "/arrival/search/detail",
    component: _3943e0cb,
    name: "arrival-search-detail"
  }, {
    path: "/plan/list/edit",
    component: _ea39bcc2,
    name: "plan-list-edit"
  }, {
    path: "/arrival/list/detail/edit",
    component: _a57613d2,
    name: "arrival-list-detail-edit"
  }, {
    path: "/",
    component: _3a0d2618,
    name: "index"
  }, {
    path: "*",
    component: _386d9204,
    name: "notFound"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
