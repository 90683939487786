<template lang="pug">
  v-app(v-cloak)
    TheHeader
    v-content.bg_color
      v-container.pt-0(fluid fill-height)
        v-fade-transition(name="page")
          v-row.white.main-container(no-gutters)
            nuxt
    v-overlay(:value.sync="isLoading")
      v-progress-circular(indeterminate color="#FFFFFF")
</template>

<script>
import { mapGetters } from 'vuex'
import { initialize } from '@/common/store-function'
import TheHeader from '@/components/layouts/TheHeader.vue'

export default {
  middleware: 'authorization',
  components: {
    TheHeader
  },
  computed: {
    /** GET：Vuex */
    ...mapGetters({
      isLoading: initialize.GETTERS.IS_LOADING
    })
  }
}
</script>

/** 高さ計算内容：ブラウザの高さ - ツールバー - コンテンツpadding 子コンポーネントへの伝播のためscoped指定はしない。 */
<style lang="sass">
$mobile-max: 812px
@mixin screen-mobile
  @media screen and (max-width: $mobile-max)
    @content

.main-container
  overflow-y: auto
  height: calc(100vh - 48px - 12px)
  @include screen-mobile
    overflow-y: hidden
    height: 100%

.layout-main-card
  min-width: 100%
  max-height: 100%
  .layout-container
    overflow-y: auto
    height: calc(100% - 64px)
    @include screen-mobile
      overflow-y: auto
      height: auto
</style>
