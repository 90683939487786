import { settingUser } from '@/common/store-function'
const contextPath = '/v1/setting/user'

/** STORE STATE */
export const state = () => ({
  userInfo: {
    id: Number,
    roleId: Number,
    centerId: Number,
    centerInchargeId: Number,
    loginCode: String,
    name: String,
    mail: String,
    password: String,
    updatedAt: String
  },
  userList: [],
  searchInfo: {
    centerId: 0
  }
})

/** GETTERS */
export const getters = {
  // ユーザー情報 1件
  userInfo: state => state.userInfo,
  // ユーザー情報リスト
  userList: state => state.userList
}

/** ACTIONS */
export const actions = {
  /** 初期化 */
  async initialize({ commit }) {
    await commit('ResetStore')
  },
  /** FETCH：ユーザー情報 一覧取得 */
  async fetchList({ dispatch, commit }) {
    commit(settingUser.COMMIT.SET_SEARCH_INFO, { centerId: 0 })
    await dispatch(settingUser.ACTIONS.FETCH_SEARCH)
  },
  /** FETCH：ユーザー情報 検索 */
  async fetchSearch({ commit, state }) {
    await this.$axios.$get(`${contextPath}/search`, { params: state.searchInfo }).then(response => {
      commit(settingUser.COMMIT.SET_LIST, response)
    })
  },
  /** FETCH：ユーザー情報 検索（条件変更） */
  async fetchSearchChange({ dispatch, commit }, info) {
    commit(settingUser.COMMIT.SET_SEARCH_INFO, info)
    await dispatch(settingUser.ACTIONS.FETCH_SEARCH)
  },
  /** FETCH：ユーザー情報 1件取得 */
  fetchInfo({ commit }, info) {
    commit(settingUser.COMMIT.SET_INFO, info)
  },
  /** GET：ユーザー情報 1件取得 */
  async getInfoById({ commit }, info) {
    const result = await this.$axios.$get(`${contextPath}/${info.id}`)
    return result
  },
  /** POST：ユーザー情報 新規登録 */
  async postInfo({ dispatch, commit, state }, info) {
    // 登録失敗を考え、一旦Vuexにコミット
    commit(settingUser.COMMIT.SET_INFO, info)
    await this.$axios.$post(contextPath, state.userInfo).then(() => {
      dispatch(settingUser.ACTIONS.FETCH_SEARCH)
    })
  },
  /** PUT：ユーザー情報 1件更新 */
  async putInfoById({ dispatch, commit, state }, info) {
    // 登録失敗を考え、一旦Vuexにコミット
    commit(settingUser.COMMIT.SET_INFO, info)
    await this.$axios.$put(`${contextPath}/${state.userInfo.id}`, state.userInfo).then(() => {
      dispatch(settingUser.ACTIONS.FETCH_SEARCH)
    })
  },
  /** DELETE：ユーザー情報 1件削除 */
  async deleteInfoById({ dispatch, commit, state }, info) {
    await this.$axios.$delete(`${contextPath}/${state.userInfo.id}`, { data: info }).then(() => {
      dispatch(settingUser.ACTIONS.FETCH_SEARCH)
    })
  }
}
/** MUTATIONS */
export const mutations = {
  /** 設定：ユーザー情報 1件 */
  SetInfo(state, info) {
    state.userInfo.id = info.id
    state.userInfo.roleId = info.roleId
    state.userInfo.centerId = info.centerId
    state.userInfo.centerInchargeId = info.centerInchargeId
    state.userInfo.loginCode = info.loginCode
    state.userInfo.name = info.name
    state.userInfo.mail = info.mail
    state.userInfo.password = info.password
    state.userInfo.updatedAt = info.updatedAt
  },
  /** 設定：ユーザー情報リスト */
  SetList(state, items) {
    state.userList = items
  },
  /** 設定：ユーザー検索条件情報 */
  SetSearchInfo(state, items) {
    state.searchInfo.centerId = items.centerId
  },
  /** 初期化 */
  ResetStore(state) {
    state.userInfo = {}
    state.userList = []
    state.searchInfo = {
      centerId: 0
    }
  }
}
