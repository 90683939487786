<template lang="pug">
  v-tooltip(v-model="isShow" top v-if="tooltipText && tooltipText !== ''")
    template(v-slot:activator="{ on }")
      v-icon(small color="primary" v-on="on") mdi-help-circle-outline
    span {{ tooltipText }}
</template>

<script>
export default {
  props: {
    /** PROPS： */
    value: {
      type: Boolean,
      default: () => false,
      required: false
    },
    tooltipText: {
      type: String,
      default: () => '',
      required: false
    }
  },
  data: () => ({}),
  computed: {
    isShow: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('clickTooltip', newValue)
      }
    }
  }
}
</script>
