import Vue from 'vue'

/* axios：共通設定 ※一部設定はnuxt.config.jsに記述 */
export default function({ $axios, store, redirect }) {
  $axios.onRequest(config => {
    // 設定：APIベースURL
    config.baseURL = process.env.apiBaseUrl
    // 設定：トークン
    if (store.state.auth.token) {
      // ※ヘッダーまたはパラメータに指定する必要がある（SpringSecurityデフォルト仕様）
      config.headers.common[process.env.apiCsrfTokenName] = store.state.auth.token
    }
    return config
  })

  /** エラーハンドリング */
  $axios.onError(error => {
    const code = parseInt(error.response && error.response.status)
    let errorMessage
    // responseType:blobを設定しているか判定
    if ('responseType' in error.request && error.request.responseType === 'blob') {
      executeGetMassageForBlob(error).then(value => Vue.toasted.error(value))
    } else {
      switch (code) {
        case 401:
        case 403:
        case 404:
          errorMessage = error.response.data.message || '表示できません。'
          Vue.toasted.error(errorMessage)
          break
        case 408:
          errorMessage = error.response.data.message || 'サーバーとの接続が切断されました。'
          Vue.toasted.error(errorMessage)
          redirect(process.env.errorUrl)
          break
        case undefined:
        case 500:
          errorMessage = error.response.data.message || '予期せぬ問題が発生しました。'
          redirect(process.env.errorUrl)
          break
        default:
          if (error.response) errorMessage = 'message' in error.response.data ? error.response.data.message : ''
          errorMessage = errorMessage || '正常に接続できませんでした。'
          Vue.toasted.error(errorMessage)
      }
    }
  })

  /** 処理：Blodデータを読込エラーメッセージを取得 */
  async function executeGetMassageForBlob(error) {
    const blobData = await error.response.data.text()
    const json = await JSON.parse(blobData)
    return json.message
  }
}
