import Vue from 'vue'
import { plan } from '@/common/store-function'
const contextPath = '/v1/plan'
const errorDuplicateMessage = '入力されたShipNoは既に表示されています。'
const errorNoInputMessage = '配車情報を入力してください。'

/** STORE STATE */
export const state = () => ({
  /** STORE：配車情報検索条件 */
  searchInfo: {
    workDate: Date,
    shipNo: String
  },
  /** STORE：配車一覧 */
  editInfoList: []
})

/** GETTERS */
export const getters = {
  searchInfo: state => state.searchInfo,
  editInfoList: state => state.editInfoList
}

/** ACTIONS */
export const actions = {
  /** 初期化 */
  async initialize({ commit }) {
    await commit('ResetStore')
  },
  /** FETCH：配車情報 条件設定 */
  async fetchSearchInfo({ commit, state, dispatch }, info) {
    // 作業日が更新された場合、editInfoListをクリア
    if ('workDate' in info) {
      if (state.searchInfo.workDate !== info.workDate) dispatch(plan.ACTIONS.FETCH_CLEAR_LIST)
    }
    await commit(plan.COMMIT.SET_SEARCH_INFO, info)
  },
  /** FETCH：配車情報 更新情報設定 */
  async fetchEditInfo({ commit }, { info, rowIndex }) {
    await commit(plan.COMMIT.SET_EDIT_INFO_LIST_FOR_REWRITE, { info, rowIndex })
  },
  /** FETCH：配車情報 更新情報リスト初期化 */
  async fetchClearList({ commit }) {
    // ShipNoクリア処理
    await commit(plan.COMMIT.SET_SEARCH_INFO, { shipNo: '' })
    await commit(plan.COMMIT.RESET_EDIT_INFO_LIST)
  },
  /** GET：配車情報 条件取得 */
  async getInfo({ dispatch, state, commit }, { info, rowIndex }) {
    // 条件設定
    dispatch(plan.ACTIONS.FETCH_SEARCH_INFO, info)
    // 検索条件がそろっている場合のみ検索
    if (state.searchInfo.workDate && state.searchInfo.shipNo) {
      // 配車情報を1件取得
      await this.$axios
        .$get(contextPath, { params: state.searchInfo })
        .then(response => {
          // ※ResponseにShipNoは含まれないための対応
          const planInfo = { ...response.planInfo, ...{ shipNo: state.searchInfo.shipNo } }
          // 設定：更新情報リスト
          commit(plan.COMMIT.SET_EDIT_INFO_LIST, { info: planInfo, rowIndex })
          return planInfo
        })
        .catch(() => {
          // 存在しなかった場合リセット
          commit(plan.COMMIT.RESET_EDIT_INFO_LIST_BY_INDEX, rowIndex)
        })
        .finally(() => {
          // ShipNoクリア処理
          commit(plan.COMMIT.SET_SEARCH_INFO, { shipNo: '' })
        })
    } else {
      // 検索条件：未指定
      commit(plan.COMMIT.RESET_EDIT_INFO_LIST_BY_INDEX, rowIndex)
    }
  },
  /** PUT：配車情報 一括更新 */
  async putInfoList({ commit, state }) {
    if (state.editInfoList.some(editInfo => editInfo.arrivalId !== null)) {
      // arrivalIdが1件でも存在すればPUT処理を行う
      await this.$axios
        .$put(contextPath, { planInfoList: state.editInfoList.filter(editInfo => editInfo.arrivalId !== null) })
        .then(() => {
          // 正常終了時、Stateのリストをクリアする
          commit(plan.COMMIT.RESET_EDIT_INFO_LIST)
        })
    } else {
      // 保存ボタンをエラー扱いで再活性化させるため、Promiseをrejectで返却する
      Vue.toasted.error(errorNoInputMessage)
      return new Promise((resolve, reject) => reject(new Error()))
    }
  }
}

/** MUTATIONS */
export const mutations = {
  /** 設定：配車情報 検索条件 */
  SetSearchInfo(state, info) {
    state.searchInfo.workDate = 'workDate' in info ? info.workDate : state.searchInfo.workDate
    state.searchInfo.shipNo = 'shipNo' in info ? (info.shipNo === '' ? null : info.shipNo) : state.searchInfo.shipNo
  },
  /** 設定：配車情報 更新情報リスト 検索結果保管用 */
  SetEditInfoList(state, { info, rowIndex }) {
    // 同じ行、同じShipNoによる再検索を行った場合は上書き
    if (state.editInfoList.findIndex(editInfo => editInfo.arrivalId === info.arrivalId) === rowIndex) {
      // 電話番号・車輛番号のハイフンを除去する
      info.vehicleNo = info.vehicleNo ? info.vehicleNo.replace(/-/g, '') : null
      info.driverTel = info.driverTel ? info.driverTel.replace(/-/g, '') : null
      state.editInfoList.splice(rowIndex, 1, info)
      return
    }
    // ArrivalIDが設定されていなかった場合、及びすでに同じArrivalIDが存在した場合はエラー
    if (!info.arrivalId || state.editInfoList.some(editInfo => editInfo.arrivalId === info.arrivalId)) {
      Vue.toasted.error(errorDuplicateMessage)
      this.ResetEditInfoListByIndex(rowIndex)
    } else {
      // 電話番号・車輛番号のハイフンを除去する
      info.vehicleNo = info.vehicleNo ? info.vehicleNo.replace(/-/g, '') : null
      info.driverTel = info.driverTel ? info.driverTel.replace(/-/g, '') : null
      state.editInfoList.splice(rowIndex, 1, info)
    }
  },
  /** 設定：配車情報 更新情報リスト 内容更新時 */
  SetEditInfoListForRewrite(state, { info, rowIndex }) {
    if (!info.arrivalId) {
      state.editInfoList.splice(rowIndex, 1, info)
    }
    // 該当ArrivalIDの行数が、指定行と一致しなかった場合、不正データとしてエラー
    else if (state.editInfoList.findIndex(editInfo => editInfo.arrivalId === info.arrivalId) !== rowIndex) {
      Vue.toasted.error(errorDuplicateMessage)
    } else {
      // 電話番号・車輛番号のハイフンを除去する
      info.vehicleNo = info.vehicleNo ? info.vehicleNo.replace(/-/g, '') : null
      info.driverTel = info.driverTel ? info.driverTel.replace(/-/g, '') : null
      state.editInfoList.splice(rowIndex, 1, info)
    }
  },
  /** 初期化：配車情報 更新情報リスト */
  ResetEditInfoList(state) {
    // 初期化用リスト作成
    const initialList = new Array(process.env.maxPlanList).fill(null).map((_, i) => ({
      arrivalId: null,
      shipNo: null,
      shippingName: null,
      vehicleType: null,
      vehicleNo: null,
      driverName: null,
      driverTel: null,
      driverMail: null,
      updatedAt: null
    }))
    state.editInfoList = initialList
  },
  /** 初期化：配車情報 更新情報リスト（行指定） */
  ResetEditInfoListByIndex(state, rowIndex) {
    const resetInfo = {
      arrivalId: null,
      shipNo: state.searchInfo.shipNo,
      shippingName: null,
      vehicleType: null,
      vehicleNo: null,
      driverName: null,
      driverTel: null,
      driverMail: null,
      updatedAt: null
    }
    state.editInfoList.splice(rowIndex, 1, resetInfo)
  },
  /** 初期化 */
  ResetStore(state) {
    state.searchInfo = {}
    state.editInfoList = []
  }
}
