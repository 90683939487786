<template lang="pug">
  v-text-field.text-dash.pt-4.text-field-right(v-model="inputData" :label="formLabel" :placeholder="placeholder"
  :readonly="isReadOnly" :disabled="isDisable" hide-details v-mask="mask")
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: () => '',
      required: false
    },
    formLabel: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: () => null,
      required: false
    },
    isReadOnly: {
      type: Boolean,
      default: () => false,
      required: false
    },
    isDisable: {
      type: Boolean,
      default: () => false,
      required: false
    }
  },
  data: () => ({
    vehicleNo: ''
  }),
  computed: {
    inputData: {
      get() {
        return this.$maskVehicleNo(this.value)
      },
      set(changeValue) {
        this.vehicleNo = changeValue
        if (this.value !== changeValue) this.$emit('input', changeValue)
      }
    },
    mask() {
      const length = this.vehicleNo.replace('-', '').length
      let ret = ''
      switch (length) {
        case 3:
          ret = 'X-XX'
          break
        case 2:
        case 1:
          ret = 'XX'
          break
        default:
          ret = 'XX-XX'
          break
      }
      return ret
    }
  },
  created() {
    this.vehicleNo = this.value
  }
}
</script>
