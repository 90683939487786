import qs from 'qs'
import { berth, arrival } from '@/common/store-function'
const contextPath = '/v1/berth'

/** STORE STATE */
export const state = () => ({
  berthInfo: {},
  berthInfoList: [],
  berthStandbyList: [],
  scheduledInfo: {},
  standbyInfo: {},
  searchInfo: {
    workDate: '',
    workStatusInfo: {
      berthIdList: [],
      workStatusIdList: []
    }
  }
})

/** GETTERS */
export const getters = {
  berthInfo: state => state.berthInfo,
  berthInfoList: state => state.berthInfoList,
  berthStandbyList: state => state.berthStandbyList,
  scheduledInfo: state => state.scheduledInfo,
  standbyInfo: state => state.standbyInfo,
  searchInfo: state => state.searchInfo
}

/** ACTIONS */
export const actions = {
  /** 初期化 */
  async initialize({ commit }) {
    await commit('ResetStore')
  },
  /** GET：バース 一覧取得 */
  async getListByBerthId({ commit, state }) {
    await this.$axios
      .$get(`${contextPath}`, {
        params: state.searchInfo,
        paramsSerializer: params => {
          return qs.stringify(params, { allowDots: true })
        }
      })
      .then(response => {
        commit(berth.COMMIT.SET_LIST, response)
      })
  },
  /** GET：arrival.jsにて受付情報取得＋誘導状況取得 */
  getArrivalInfoById({ dispatch, commit, rootState }, info) {
    dispatch(`arrival/${arrival.ACTIONS.GET_BY_ID}`, info, { root: true })
  },
  /** GET：バース情報 予定車両数 */
  async getScheduledInfoByCenter({ commit }, info) {
    await this.$axios.$get(`${contextPath}/schedule/count`, { params: info }).then(response => {
      commit(berth.COMMIT.SET_SCHEDULED_INFO, response)
    })
  },
  /** GET：バース情報 待機車両数 */
  async getStandbyInfoByCenter({ commit }, info) {
    await this.$axios.$get(`${contextPath}/standby/count`, { params: info }).then(response => {
      commit(berth.COMMIT.SET_STANDBY_INFO, response)
    })
  },
  /** GET：バース 待機車両 一覧取得 */
  async getStandbyList({ commit, state }) {
    await this.$axios
      .$get(`${contextPath}/standby`, {
        params: state.searchInfo,
        paramsSerializer: params => {
          return qs.stringify(params, { allowDots: true })
        }
      })
      .then(response => {
        commit(berth.COMMIT.SET_STANDBY_LIST, response)
      })
  },
  /** PUT: バース 通知 1件更新 */
  async putNoticeById({ dispatch }, info) {
    await this.$axios.$put(`${contextPath}/notice/${info.id}`, info).then(resonse => {
      dispatch(berth.ACTIONS.GET_LIST_BY_BERTH_ID)
      dispatch(berth.ACTIONS.GET_STANDBY_LIST)
    })
  },
  /** PUT:バース 作業状況 1件更新 */
  async putWorkstatusById({ dispatch }, info) {
    await this.$axios.$put(`${contextPath}/workstatus/${info.id}`, info).then(response => {
      // リフトマン画面更新
      dispatch(berth.ACTIONS.GET_LIST_BY_BERTH_ID)
      dispatch(berth.ACTIONS.GET_STANDBY_LIST)
    })
  },
  fetchSearchInfo({ commit }, info) {
    commit(berth.COMMIT.SET_SEARCH_INFO, info)
  }
}

/** MUTATIONS */
export const mutations = {
  /** 変換： */
  SetInfo(state, info) {
    state.berthInfo = info
  },
  /** 変換： */
  SetList(state, infoList) {
    state.berthInfoList = infoList
  },
  /** 変換： */
  SetStandbyList(state, standbyList) {
    state.berthStandbyList = standbyList
  },
  /** 変換： */
  SetScheduledInfo(state, info) {
    state.scheduledInfo = info
  },
  /** 変換： */
  SetStandbyInfo(state, info) {
    state.standbyInfo = info
  },
  /** 設定：検索情報 */
  SetSearchInfo(state, info) {
    if ('workDate' in info) state.searchInfo.workDate = info.workDate
    if ('workStatusInfo' in info) {
      if ('berthIdList' in info.workStatusInfo)
        state.searchInfo.workStatusInfo.berthIdList = info.workStatusInfo.berthIdList
      if ('workStatusIdList' in info.workStatusInfo)
        state.searchInfo.workStatusInfo.workStatusIdList = info.workStatusInfo.workStatusIdList
    }
  },
  /** 初期化 */
  ResetStore(state) {
    state.berthInfo = {}
    state.scheduledInfo = {}
    state.standbyInfo = {}
    state.searchInfo = {
      workDate: '',
      workStatusInfo: {
        berthIdList: [],
        workStatusIdList: []
      }
    }
    state.berthInfoList = []
    state.berthStandbyList = []
  }
}
