<template lang="pug">
  v-text-field.text-field-right.pt-0(flat single-line hide-details readonly :prefix="prefix" :suffix="suffix" :value="value")
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: () => '',
      required: false
    },
    prefix: {
      type: String,
      default: () => '',
      required: false
    },
    suffix: {
      type: String,
      default: () => '',
      required: false
    }
  }
}
</script>

<style scoped>
.text-field-right >>> input {
  text-align: right !important;
}
</style>
