<template lang="pug">
  v-btn.custom-button(tile depressed color="primary" @click="onClick")
    v-icon(left) mdi-email
    slot 誘導
</template>

<script>
export default {
  props: {},
  methods: {
    onClick() {
      this.$emit('clickMail')
    }
  }
}
</script>

<style lang="sass" scoped>
.custom-button
  padding: 0 16px !important
</style>
