<template lang="pug">
  header
    v-navigation-drawer.pt-0.header_drawer.drawer-width(touchless app dark :width="drawerWidth" expand-on-hover hide-overlay permanent :mini-variant.sync="mini")
      v-toolbar.transparent(flat :height="miniDrawerHeight")
      v-list.pt-0(dense)
        TheSideMenu(v-for="menuGroup in menuInfo.sideMenu" :key="menuGroup.id" :menuGroup="menuGroup")
    v-toolbar.header_toolbar(flat dense :height="miniDrawerHeight")
      v-toolbar-title.white--text(style="margin-left:60px") {{ loginUser.centerName }}
      v-spacer
      v-menu(transition="slide-y-transition")
        template(v-slot:activator="{ on }")
          v-btn(icon v-on="on")
            v-icon(color="white") mdi-account
        v-list
          v-list-item
            v-list-item-icon
              v-icon mdi-account
            v-list-item-content {{ loginUser.name }}
          v-list-item(v-if="isAllCenterIncharge" @click="clickCenterSetting")
            v-list-item-icon
              v-icon mdi-factory
            v-list-item-content センター設定
          v-list-item(href="/manual.pdf" target="_blank")
            v-list-item-icon
              v-icon mdi-help-circle
            v-list-item-content マニュアル
          v-list-item(@click="clickLogout")
            v-list-item-icon
              v-icon mdi-exit-to-app
            v-list-item-content ログアウト
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { auth, initialize } from '@/common/store-function'
import TheSideMenu from '@/components/layouts/TheSideMenu.vue'

export default {
  components: {
    TheSideMenu
  },
  data: () => ({
    miniDrawerHeight: 48,
    drawerWidth: '28vmax'
  }),
  computed: {
    /** GET：Vuex */
    ...mapGetters({
      loginUser: auth.GETTERS.LOGIN_USER,
      menuInfo: auth.GETTERS.MENU_INFO
    }),
    mini: {
      get() {
        // GettersではSetで問題がでるための対応
        return this.$store.getters['initialize.GETTERS.IS_MINI']
      },
      set(value) {
        this.toggleMiniDrawer(value)
      }
    },
    isAllCenterIncharge: {
      get() {
        return this.loginUser.centerInchargeId === 0
      }
    }
  },
  methods: {
    /** ACTION：Vuex */
    ...mapActions(auth.BASE, {
      logout: auth.ACTIONS.LOGOUT
    }),
    ...mapActions(initialize.BASE, {
      toggleMiniDrawer: initialize.ACTIONS.TOGGLE_MINI_DRAWER
    }),
    /** 処理：ログアウト */
    async clickLogout() {
      await this.logout()
      this.$router.push(process.env.loginUrl)
    },
    /** 処理：センター選択画面遷移 */
    clickCenterSetting() {
      this.$router.push({ name: process.env.authUrl, params: { from: this.$constant.auth.header } })
    }
  }
}
</script>

<style lang="sass" scoped>
.v-navigation-drawer
  .v-menu--inline
    display: block

.drawer-width
  max-width: 400px
</style>
