/** store/auth.js */
export const auth = {
  BASE: 'auth',
  GETTERS: {
    LOGIN_USER: 'auth/loginUser',
    MENU_INFO: 'auth/menuInfo'
  },
  ACTIONS: {
    LOGIN: 'login',
    LOGOUT: 'logout',
    PUT_AUTH_INFO: 'putAuthInfo',
    STORE_INITIALIZE: 'storeInitialize'
  },
  COMMIT: {
    SET_LOGIN_USER: 'SetLoginUser',
    SET_MENU_INFO: 'SetMenuInfo',
    SET_TOKEN: 'SetToken'
  }
}

/** store/main.js */
export const main = {
  BASE: 'main',
  GETTERS: {
    INFORMATION_LIST: 'main/informationList'
  },
  COMMIT: {
    SET_INFORMATION_LIST: 'SetInformationList'
  },
  // asyncData用
  A_FETCH_INFORMATION_LIST: 'main/fetchInformationList'
}

/** store/arrival.js */
export const arrival = {
  CONTEXT: '/arrival',
  BASE: 'arrival',
  GETTERS: {
    INFO: 'arrival/arrivalInfo',
    INFO_LIST: 'arrival/arrivalInfoList',
    SCHEDULE_INFO_LIST: 'arrival/scheduledInfoList',
    ITEM_LIST: 'arrival/arrivalItemList',
    SELECT_CATEGORY: 'arrival/selectCategory',
    SELECT_ARRIVAL_STATUS: 'arrival/selectArrivalStatus',
    SELECT_ARRIVAL_STATUS_NAME: 'arrival/selectArrivalStatusName',
    IMPORT_LIST: 'arrival/importList',
    EXPORT_LIST: 'arrival/exportList',
    SERACH_CONDITION_FOR_INFO_LIST: 'arrival/searchConditionForInfoList',
    SERACH_CONDITION: 'arrival/searchCondition'
  },
  ACTIONS: {
    /** FETCH：受付情報 1件取得 */
    FETCH_INFO: 'fetchInfo',
    /** POST：受付情報 新規登録 */
    POST_INFO: 'postInfo',
    /** PUT：受付情報 1件更新 */
    PUT_INFO_BY_ID: 'putInfoById',
    /** DELETE：受付情報 1件削除 */
    DELETE_INFO_BY_ID: 'deleteInfoById',
    /** GET：積地／卸地名称 条件取得 */
    GET_DESTINATION_LIST: 'getDestinationList',
    /** PUT：作業状況情報 1件更新 */
    PUT_WORK_STATUS_INFO_BY_ID: 'putWorkStatusInfoById',
    /** FETCH：受付情報 一覧 条件取得 */
    FETCH_INFO_LIST: 'fetchInfoList',
    /** FETCH：受付情報 他センター情報一覧 条件取得 */
    FETCH_SEARCH: 'fetchSearch',
    // GET：受付情報 予定車両数 一覧取得
    GET_BY_WORKING_DAY: 'getByWorkingDay',
    /** FETCH：受付情報 インポート 一覧（履歴）取得 */
    FETCH_IMPORT_LIST: 'fetchImportList',
    /** UPLOAD：受付情報 インポートファイル 送信 */
    UPLOAD_IMPORT_FILE: 'uploadImportFile',
    /** DOWNLOAD：受付情報 インポート インポートファイル取得 */
    DOWNLOAD_IMPORT_FILE: 'downloadImportFile',
    /** DOWNLOAD：受付情報 インポート エラーログファイル取得  */
    DOWNLOAD_IMPORT_ERRORFILE: 'downloadImportErrorFile',
    /** FETCH：受付情報 エクスポート 一覧（履歴）取得 */
    FETCH_EXPORT_LIST: 'fetchExportList',
    /** DOWNLOAD：受付情報 エクスポート エクスポートファイル取得 */
    DOWNLOAD_EXPORT_FILE: 'downloadExportFile',
    /** GET:受付情報 1件取得 */
    GET_BY_ID: 'getInfoById',
    /** FETCH：受付情報 初期化 */
    FETCH_RESET_INFO: 'resetInfo'
  },
  COMMIT: {
    SET_EDIT_INFO: 'SetEditInfo',
    SET_INFO: 'SetInfo',
    RESET_INFO: 'ResetInfo',
    RESET_SCHEDULE_INFO_LIST: 'ResetScheduleInfoList',
    SET_WORK_STATUS_INFO: 'SetWorkStatusInfo',
    SET_INFO_LIST: 'SetInfoList',
    SET_SCHEDULED_INFO_LIST: 'SetScheduledInfoList',
    SET_IMPORT_LIST: 'SetImportList',
    SET_EXPORT_LIST: 'SetExportList'
  },
  // asyncData用
  A_RESET_INFO: 'arrival/resetInfo',
  A_RESET_SCHEDULE_INFO_LIST: 'arrival/resetScheduleInfoList',
  A_FETCH_IMPORT_LIST: 'arrival/fetchImportList',
  A_FETCH_EXPORT_LIST: 'arrival/fetchExportList'
}

/** store/plan.js */
export const plan = {
  BASE: 'plan',
  GETTERS: {
    SEARCH_INFO: 'plan/searchInfo',
    EDIT_INFO_LIST: 'plan/editInfoList'
  },
  ACTIONS: {
    /** FETCH：配車情報 条件設定 */
    FETCH_SEARCH_INFO: 'fetchSearchInfo',
    /** FETCH：配車情報 更新情報設定 */
    FETCH_EDIT_INFO: 'fetchEditInfo',
    /** FETCH：配車情報 更新情報リスト初期化 */
    FETCH_CLEAR_LIST: 'fetchClearList',
    /** GET：配車情報 条件取得 */
    GET_INFO: 'getInfo',
    /** PUT：配車情報 一括更新 */
    PUT_INFO_LIST: 'putInfoList'
  },
  COMMIT: {
    /** 項目設定：検索条件 */
    SET_SEARCH_INFO: 'SetSearchInfo',
    /** 項目設定：一覧項目 */
    SET_EDIT_INFO_LIST: 'SetEditInfoList',
    /** 項目設定：一覧項目（内容更新時） */
    SET_EDIT_INFO_LIST_FOR_REWRITE: 'SetEditInfoListForRewrite',
    /** 初期化：配車情報 更新情報リスト */
    RESET_EDIT_INFO_LIST: 'ResetEditInfoList',
    /** 初期化：配車情報 更新情報リスト（行指定） */
    RESET_EDIT_INFO_LIST_BY_INDEX: 'ResetEditInfoListByIndex'
  }
}

/** store/berth.js */
export const berth = {
  CONTEXT: '/berth',
  BASE: 'berth',
  GETTERS: {
    INFO: 'berth/berthInfo',
    LIST: 'berth/berthInfoList',
    SCHEDULED_INFO: 'berth/scheduledInfo',
    STANDBY_LIST: 'berth/berthStandbyList',
    STANDBY_INFO: 'berth/standbyInfo',
    SEARCH_INFO: 'berth/searchInfo'
  },
  ACTIONS: {
    SAVE: 'save',
    GET_LIST_BY_BERTH_ID: 'getListByBerthId',
    GET_ARRIVAL_INFO_BY_ID: 'getArrivalInfoById',
    GET_SCHEDULED_INFO_BY_CENTER: 'getScheduledInfoByCenter',
    GET_STANDBY_LIST: 'getStandbyList',
    GET_STANDBY_INFO_BY_CENTER: 'getStandbyInfoByCenter',
    GET_LIST: 'etchList',
    FETCH_SEARCH_INFO: 'fetchSearchInfo',
    PUT_NOTICE_BY_ID: 'putNoticeById',
    PUT_WORKSTATUS_BY_ID: 'putWorkstatusById'
  },
  COMMIT: {
    SET_SEARCH_INFO: 'SetSearchInfo',
    SET_INFO: 'SetInfo',
    SET_LIST: 'SetList',
    SET_SCHEDULED_INFO: 'SetScheduledInfo',
    SET_STANDBY_LIST: 'SetStandbyList',
    SET_STANDBY_INFO: 'SetStandbyInfo'
  }
}

/** store/initialize.js */
export const initialize = {
  BASE: 'initialize',
  GETTERS: {
    HEADER_ITEMS: 'initialize/headerItems',
    SELECT_AUTHORIZATION: 'initialize/selectAuthorization',
    SELECT_ROLE: 'initialize/selectRole',
    SELECT_CENTER: 'initialize/selectCenter',
    SELECT_CENTER_INCHARGE: 'initialize/selectCenterIncharge',
    SELECT_DATA_CATEGORY: 'initialize/selectDataCategory',
    SELECT_CATEGORY: 'initialize/selectCategory',
    SELECT_PRIORITY: 'initialize/selectPriority',
    SELECT_BERTH: 'initialize/selectBerth',
    SELECT_WORK_STATUS: 'initialize/selectWorkStatus',
    SELECT_CENTER_SEARCH: 'initialize/selectCenterSearch',
    SELECT_NOTICE_CATEGORY: 'initialize/selectNoticeCategory',
    IS_MINI: 'initialize/isMini',
    IS_LOADING: 'initialize/isLoading'
  },
  ACTIONS: {
    TOGGLE_MINI_DRAWER: 'toggleMiniDrawer',
    TOGGLE_IS_LOADING: 'toggleIsLoading'
  },
  COMMIT: {
    /** 項目設定：受付情報 */
    SET_INITIALIZE_ARRIVAL: 'SetInitializeArrival',
    /** 項目設定：受付情報一覧 */
    SET_INITIALIZE_ARRIVALINFOLIST: 'SetInitializeArrivalInfoList',
    /** 項目設定：受付情報他センター情報 */
    SET_INITIALIZE_ARRIVALSEARCHINFOLIST: 'SetInitializeArrivalSearchInfoList',
    /** 項目設定：バース状況（リフトマン画面） */
    SET_INITIALIZE_BERTH: 'SetInitializeBerth',
    /** 項目設定：お知らせ情報 */
    SET_INITIALIZE_SETTING_INFORMATION: 'SetInitializeSettingInformation',
    /** 項目設定：センター情報 */
    SET_INITIALIZE_SETTING_CENTER: 'SetInitializeSettingCenter',
    /** 項目設定：バース情報 */
    SET_INITIALIZE_SETTING_BERTH: 'SetInitializeSettingBerth',
    /** 項目設定：権限情報 */
    SET_INITIALIZE_SETTING_ROLE: 'SetInitializeSettingRole',
    /** 項目設定：ユーザー情報 */
    SET_INITIALIZE_SETTING_USER: 'SetInitializeSettingUser',
    /** 項目設定：受付情報インポート */
    SET_INITIALIZE_ARRIVALIMPORT: 'SetInitializeArrivalImport',
    /** 項目設定：受付情報エクスポート */
    SET_INITIALIZE_ARRIVALEXPORT: 'SetInitializeArrivalExport',
    /** ドロワーメニュー：ミニ状態 */
    TOGGLE_MINI_DRAWER: 'ToggleMiniDrawer',
    /** 状態変更：ローディング */
    TOGGLE_IS_LOADING: 'ToggleIsLoading'
  },
  DISPATCH: {
    /** INIT：受付情報 初期設定内容 取得処理 */
    INIT_ARRIVAL: 'initialize/initArrival',
    /** INIT：受付情報一覧 初期設定内容 取得処理 */
    INIT_ARRIVALINFOLIST: 'initialize/initArrivalInfoList',
    /** INIT：受付情報他センター情報一覧 初期設定内容 取得処理 */
    INIT_ARRIVALSEARCHINFOLIST: 'initialize/initArrivalSearchInfoList',
    /** INIT： バース状況（リフトマン画面） 取得処理 */
    INIT_BERTH: 'initialize/initBerth',
    /** INIT：お知らせ情報 初期設定内容 取得処理 */
    INIT_SETTING_INFORMATION: 'initialize/initSettingInformation',
    /** INIT：センター情報 初期設定内容 取得処理 */
    INIT_SETTING_CENTER: 'initialize/initSettingCenter',
    /** INIT：バース情報 初期設定内容 取得処理 */
    INIT_SETTING_BERTH: 'initialize/initSettingBerth',
    /** INIT：権限情報 初期設定内容 取得処理 */
    INIT_SETTING_ROLE: 'initialize/initSettingRole',
    /** INIT：ユーザー情報 初期設定内容 取得処理 */
    INIT_SETTING_USER: 'initialize/initSettingUser',
    /** INIT：受付情報インポート 初期設定内容 取得処理 */
    INIT_ARRIVALIMPORT: 'initialize/initArrivalImport',
    /** INIT：受付情報エクスポート 初期設定内容 取得処理 */
    INIT_ARRIVALEXPORT: 'initialize/initArrivalExport'
  }
}

/** store/setting/information.js */
export const settingInformation = {
  BASE: 'setting/information',
  GETTERS: {
    INFORMATION_INFO: 'setting/information/informationInfo',
    INFORMATION_LIST: 'setting/information/informationList'
  },
  ACTIONS: {
    /** FETCH：お知らせ情報 一覧取得 */
    FETCH_LIST: 'fetchList',
    /** FETCH：お知らせ情報 1件取得 */
    FETCH_INFO: 'fetchInfo',
    /** GET：お知らせ情報 1件取得 */
    GET_INFO_BY_ID: 'getInfoById',
    /** POST：お知らせ情報 新規登録 */
    POST_INFO: 'postInfo',
    /** PUT：お知らせ情報 1件更新 */
    PUT_INFO_BY_ID: 'putInfoById'
  },
  COMMIT: {
    SET_INFO: 'SetInfo',
    SET_LIST: 'SetList'
  },
  DISPATCH: {
    /** FETCH：お知らせ情報 一覧取得 */
    FETCH_LIST: 'setting/information/fetchList'
  }
}

/** store/setting/center.js */
export const settingCenter = {
  BASE: 'setting/center',
  GETTERS: {
    CENTER_INFO: 'setting/center/centerInfo',
    CENTER_LIST: 'setting/center/centerList'
  },
  ACTIONS: {
    /** FETCH：センター情報 一覧取得 */
    FETCH_LIST: 'fetchList',
    /** FETCH：センター情報 1件取得 */
    FETCH_INFO: 'fetchInfo',
    /** GET：センター情報 1件取得 */
    GET_INFO_BY_ID: 'getInfoById',
    /** POST：センター情報 新規登録 */
    POST_INFO: 'postInfo',
    /** PUT：センター情報 1件更新 */
    PUT_INFO_BY_ID: 'putInfoById'
  },
  COMMIT: {
    SET_INFO: 'SetInfo',
    SET_LIST: 'SetList'
  },
  DISPATCH: {
    /** FETCH：センター情報 一覧取得 */
    FETCH_LIST: 'setting/center/fetchList'
  }
}

/** store/setting/berth.js */
export const settingBerth = {
  BASE: 'setting/berth',
  GETTERS: {
    BERTH_INFO: 'setting/berth/berthInfo',
    BERTH_LIST: 'setting/berth/berthList'
  },
  ACTIONS: {
    /** FETCH：バース情報 一覧取得 */
    FETCH_LIST: 'fetchList',
    /** FETCH：バース情報 1件取得 */
    FETCH_INFO: 'fetchInfo',
    /** GET：バース情報 1件取得 */
    GET_INFO_BY_ID: 'getInfoById',
    /** POST：バース情報 新規登録 */
    POST_INFO: 'postInfo',
    /** PUT：バース情報 1件更新 */
    PUT_INFO_BY_ID: 'putInfoById',
    /** FETCH: バース情報検索 */
    FETCH_SEARCH: 'fetchSearch',
    /** FETCH: バース情報検索 */
    FETCH_SEARCH_CHANGE: 'fetchSearchChange'
  },
  COMMIT: {
    SET_INFO: 'SetInfo',
    SET_LIST: 'SetList',
    SET_SEARCH_INFO: 'SetSearchInfo'
  },
  DISPATCH: {
    /** FETCH：バース情報 一覧取得 */
    FETCH_LIST: 'setting/berth/fetchList'
  }
}

/** store/setting/role.js */
export const settingRole = {
  BASE: 'setting/role',
  GETTERS: {
    ROLE_INFO: 'setting/role/roleInfo',
    ROLE_LIST: 'setting/role/roleList'
  },
  ACTIONS: {
    /** FETCH：権限情報 一覧取得 */
    FETCH_LIST: 'fetchList',
    /** FETCH：権限情報 1件取得 */
    FETCH_INFO: 'fetchInfo',
    /** GET：権限情報 1件取得 */
    GET_INFO_BY_ID: 'getInfoById',
    /** POST：権限情報 新規登録 */
    POST_INFO: 'postInfo',
    /** PUT：認可情報 1件更新 */
    PUT_INFO_BY_ID: 'putInfoById'
  },
  COMMIT: {
    SET_INFO: 'SetInfo',
    SET_LIST: 'SetList'
  },
  DISPATCH: {
    /** FETCH：権限情報 一覧取得 */
    FETCH_LIST: 'setting/role/fetchList'
  }
}

/** store/setting/user.js */
export const settingUser = {
  BASE: 'setting/user',
  GETTERS: {
    USER_INFO: 'setting/user/userInfo',
    USER_LIST: 'setting/user/userList'
  },
  ACTIONS: {
    /** FETCH：ユーザー情報 一覧取得 */
    FETCH_LIST: 'fetchList',
    /** FETCH：ユーザー情報 1件取得 */
    FETCH_INFO: 'fetchInfo',
    /** GET：ユーザー情報 1件取得 */
    GET_INFO_BY_ID: 'getInfoById',
    /** POST：ユーザー情報 新規登録 */
    POST_INFO: 'postInfo',
    /** PUT：ユーザー情報 1件更新 */
    PUT_INFO_BY_ID: 'putInfoById',
    /** DELETE：ユーザー情報 1件削除 */
    DELETE_INFO_BY_ID: 'deleteInfoById',
    /** FETCH: ユーザ情報検索 */
    FETCH_SEARCH: 'fetchSearch',
    /** FETCH: ユーザ情報検索 */
    FETCH_SEARCH_CHANGE: 'fetchSearchChange'
  },
  COMMIT: {
    SET_INFO: 'SetInfo',
    SET_LIST: 'SetList',
    SET_SEARCH_INFO: 'SetSearchInfo'
  },
  DISPATCH: {
    /** FETCH：ユーザー情報 一覧取得 */
    FETCH_LIST: 'setting/user/fetchList'
  }
}
