<template lang="pug">
  v-text-field.text-dash.pt-4(v-model="inputData" prepend-inner-icon="mdi-email" :label="formLabel"
  :readonly="isReadOnly" :disabled="isDisable" hide-details :rules="rules")
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: () => '',
      required: false
    },
    formLabel: {
      type: String,
      required: true
    },
    isReadOnly: {
      type: Boolean,
      default: () => false,
      requied: false
    },
    isDisable: {
      type: Boolean,
      default: () => false,
      requied: false
    }
  },
  data: () => ({
    rules: []
  }),
  computed: {
    inputData: {
      get() {
        return this.value
      },
      set(changeValue) {
        if (this.value !== changeValue) this.$emit('input', changeValue)
      }
    }
  }
}
</script>
