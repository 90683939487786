<template lang="pug">
  v-text-field.text-dash.pt-4(v-model="inputData" :append-icon="appendIcon" :label="formLabel" :placeholder="placeholder"
  :readonly="isReadOnly" :disabled="isDisable" hide-details :rules="rules" :maxlength="maxlength")
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: () => '',
      required: false
    },
    appendIcon: {
      type: String,
      default: () => null,
      required: false
    },
    formLabel: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: () => null,
      required: false
    },
    isReadOnly: {
      type: Boolean,
      default: () => false,
      required: false
    },
    isDisable: {
      type: Boolean,
      default: () => false,
      required: false
    },
    rules: {
      type: Array,
      default: () => [],
      required: false
    },
    maxlength: {
      type: Number,
      default: () => null,
      required: false
    }
  },
  computed: {
    inputData: {
      get() {
        return this.value
      },
      set(changeValue) {
        if (this.value !== changeValue) this.$emit('input', changeValue)
      }
    }
  }
}
</script>
