<template lang="pug">
  v-text-field.text-dash.pt-4(v-model="inputData" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :label="formLabel" :rules="rules"
  :type="show ? 'text' : 'password'" :readonly="isReadOnly" :disabled="isDisable" maxlength="32" hide-details @click:append="show = !show")
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: () => '',
      required: false
    },
    formLabel: {
      type: String,
      required: true
    },
    isReadOnly: {
      type: Boolean,
      default: () => false,
      requied: false
    },
    isDisable: {
      type: Boolean,
      default: () => false,
      requied: false
    }
  },
  data: () => ({
    show: false,
    rules: []
  }),
  computed: {
    inputData: {
      get() {
        return this.value
      },
      set(changeValue) {
        if (this.value !== changeValue) this.$emit('input', changeValue)
      }
    }
  }
}
</script>
