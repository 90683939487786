<template lang="pug">
  v-select.text-dash.pt-4(v-model="selectData" :items="selectItems" item-text="label" item-value="value" :label="formLabel"
  :readonly="isReadOnly" :disabled="isDisable" :append-icon="displayIcon" hide-details return-object @input="changeValue")
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Object, Array],
      default: () => ({
        value: 0
      }),
      required: false
    },
    selectItems: {
      type: Array,
      required: true
    },
    formLabel: {
      type: String,
      required: true
    },
    isReadOnly: {
      type: Boolean,
      default: () => false,
      requied: false
    },
    isDisable: {
      type: Boolean,
      default: () => false,
      requied: false
    }
  },
  computed: {
    selectData: {
      get() {
        return this.value
      },
      set(changeValue) {
        this.$emit('input', changeValue)
      }
    },
    displayIcon() {
      // readonly,disableの場合、矢印アイコンを非表示
      return this.isReadOnly || this.isDisable ? '' : 'mdi-menu-down'
    }
  },
  methods: {
    changeValue(value) {
      this.$emit('changeValue', value)
    }
  }
}
</script>
