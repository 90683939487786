<template lang="pug">
  v-checkbox(v-model="isChecked" :label="formLabel" :readonly="isReadOnly" :disabled="isDisable" hide-details)
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    formLabel: {
      type: String,
      required: true
    },
    isReadOnly: {
      type: Boolean,
      default: () => false,
      requied: false
    },
    isDisable: {
      type: Boolean,
      default: () => false,
      requied: false
    }
  },
  computed: {
    isChecked: {
      get() {
        return this.value
      },
      set(changeValue) {
        if (this.value !== changeValue) this.$emit('input', changeValue)
      }
    }
  }
}
</script>
