import { initialize } from '@/common/store-function'
const contextPath = '/v1/initialize'

/** STORE STATE */
export const state = () => ({
  headerItems: [],
  selectAuthorization: [],
  selectRole: [],
  selectCenter: [],
  selectCenterIncharge: [],
  selectDataCategory: [],
  selectCategory: [],
  selectPriority: [],
  selectBerth: [],
  selectWorkStatus: [],
  selectNoticeCategory: [],
  selectCenterSearch: [],
  /** STORE：ミニドロワー用 */
  isMini: true,
  /** STORE：ローディング表示用 */
  isLoading: false
})

/** GETTERS */
export const getters = {
  // テーブル：ヘッダー項目名
  headerItems: state => state.headerItems,
  // 選択項目：認可
  selectAuthorization: state => state.selectAuthorization,
  // 選択項目：権限
  selectRole: state => state.selectRole,
  // 選択項目：センター
  selectCenter: state => state.selectCenter,
  // 選択項目：担当センター
  selectCenterIncharge: state => state.selectCenterIncharge,
  // 選択項目：データ区分
  selectDataCategory: state => state.selectDataCategory,
  // 選択項目：区分
  selectCategory: state => state.selectCategory,
  // 選択項目：優先度
  selectPriority: state => state.selectPriority,
  // 選択項目：バース
  selectBerth: state => state.selectBerth,
  // 選択項目：作業状況
  selectWorkStatus: state => state.selectWorkStatus,
  // 選択項目：通知区分
  selectNoticeCategory: state => state.selectNoticeCategory,
  // 選択項目：センター（検索用）
  selectCenterSearch: state => state.selectCenterSearch,
  // ドロワーメニュー：ミニ状態
  isMini: state => state.isMini,
  // ローディング表示
  isLoading: state => state.isLoading
}

/** ACTIONS */
export const actions = {
  /** 初期化 */
  async initialize({ commit }) {
    await commit('ResetStore')
  },
  /** INIT：受付情報 初期設定内容 取得処理 */
  async initArrival({ commit }) {
    await this.$axios.$get(`${contextPath}/arrival`).then(response => {
      commit(initialize.COMMIT.SET_INITIALIZE_ARRIVAL, response)
    })
  },
  /** INIT：受付情報一覧 初期設定内容 取得処理 */
  async initArrivalInfoList({ commit }) {
    await this.$axios.$get(`${contextPath}/arrival/infolist`).then(response => {
      commit(initialize.COMMIT.SET_INITIALIZE_ARRIVALINFOLIST, response)
    })
  },
  /** INIT：受付情報他センター検索 初期設定内容 取得処理 */
  async initArrivalSearchInfoList({ commit }) {
    await this.$axios.$get(`${contextPath}/arrival/search`).then(response => {
      commit(initialize.COMMIT.SET_INITIALIZE_ARRIVALSEARCHINFOLIST, response)
    })
  },
  /** INIT:バース状況（リフトマン画面） 取得処理 */
  async initBerth({ commit }) {
    await this.$axios.$get(`${contextPath}/berth`).then(response => {
      commit(initialize.COMMIT.SET_INITIALIZE_BERTH, response)
    })
  },
  /** INIT：お知らせ情報 初期設定内容 取得処理 */
  async initSettingInformation({ commit }) {
    await this.$axios.$get(`${contextPath}/setting/information`).then(response => {
      commit(initialize.COMMIT.SET_INITIALIZE_SETTING_INFORMATION, response)
    })
  },
  /** INIT：センター情報 初期設定内容 取得処理 */
  async initSettingCenter({ commit }) {
    await this.$axios.$get(`${contextPath}/setting/center`).then(response => {
      commit(initialize.COMMIT.SET_INITIALIZE_SETTING_CENTER, response)
    })
  },
  /** INIT：バース情報 初期設定内容 取得処理 */
  async initSettingBerth({ commit }) {
    await this.$axios.$get(`${contextPath}/setting/berth`).then(response => {
      commit(initialize.COMMIT.SET_INITIALIZE_SETTING_BERTH, response)
    })
  },
  /** INIT：権限情報 初期設定内容 取得処理 */
  async initSettingRole({ commit }) {
    await this.$axios.$get(`${contextPath}/setting/role`).then(response => {
      commit(initialize.COMMIT.SET_INITIALIZE_SETTING_ROLE, response)
    })
  },
  /** INIT：ユーザー情報 初期設定内容 取得処理 */
  async initSettingUser({ commit }) {
    await this.$axios.$get(`${contextPath}/setting/user`).then(response => {
      commit(initialize.COMMIT.SET_INITIALIZE_SETTING_USER, response)
    })
  },
  /** INIT：受付情報インポート 初期設定内容 取得処理 */
  async initArrivalImport({ commit }) {
    await this.$axios.$get(`${contextPath}/arrival/import`).then(response => {
      commit(initialize.COMMIT.SET_INITIALIZE_ARRIVALIMPORT, response)
    })
  },
  /** INIT：受付情報エクスポート 初期設定内容 取得処理 */
  async initArrivalExport({ commit }) {
    await this.$axios.$get(`${contextPath}/arrival/export`).then(response => {
      commit(initialize.COMMIT.SET_INITIALIZE_ARRIVALEXPORT, response)
    })
  },
  /** 処理：ミニドロワー状態変更 */
  toggleMiniDrawer({ commit }, value) {
    commit(initialize.COMMIT.TOGGLE_MINI_DRAWER, value)
  },
  /** 処理：ローディング状態変更 */
  toggleIsLoading({ commit }, value) {
    commit(initialize.COMMIT.TOGGLE_IS_LOADING, value)
  }
}

/** MUTATIONS */
export const mutations = {
  /** 項目設定：受付情報 */
  SetInitializeArrival(state, initialObject) {
    state.selectDataCategory = initialObject.selectDataCategory
    state.selectCategory = initialObject.selectCategory
    state.selectPriority = initialObject.selectPriority
    state.selectBerth = initialObject.selectBerth
    state.selectWorkStatus = initialObject.selectWorkStatus
  },
  /** 項目設定：受付情報一覧 */
  SetInitializeArrivalInfoList(state, initialObject) {
    state.headerItems = initialObject.headerItems
    state.selectDataCategory = initialObject.selectDataCategory
    state.selectCategory = initialObject.selectCategory
    state.selectPriority = initialObject.selectPriority
    state.selectBerth = initialObject.selectBerth
    state.selectWorkStatus = initialObject.selectWorkStatus
  },
  /** 項目設定：他センター検索 */
  SetInitializeArrivalSearchInfoList(state, initialObject) {
    state.headerItems = initialObject.headerItems
    state.selectCenter = initialObject.selectCenter
    state.selectWorkStatus = initialObject.selectWorkStatus
    state.selectDataCategory = initialObject.selectDataCategory
    state.selectCategory = initialObject.selectCategory
  },
  /** 項目設定：バース状況（リフトマン画面） */
  SetInitializeBerth(state, initialObject) {
    state.selectBerth = initialObject.selectBerth
    state.selectWorkStatus = initialObject.selectWorkStatus
    state.selectNoticeCategory = initialObject.selectNoticeCategory
    state.selectPriority = initialObject.selectPriority
  },
  /** 項目設定：お知らせ情報 */
  SetInitializeSettingInformation(state, initialObject) {
    state.headerItems = initialObject.headerItems
  },
  /** 項目設定：センター情報 */
  SetInitializeSettingCenter(state, initialObject) {
    state.headerItems = initialObject.headerItems
  },
  /** 項目設定：バース情報 */
  SetInitializeSettingBerth(state, initialObject) {
    state.headerItems = initialObject.headerItems
    state.selectCenter = initialObject.selectCenter
    state.selectCenterSearch = initialObject.selectCenterSearch
  },
  /** 項目設定：権限情報 */
  SetInitializeSettingRole(state, initialObject) {
    state.headerItems = initialObject.headerItems
    state.selectAuthorization = initialObject.selectAuthorization
  },
  /** 項目設定：ユーザー情報 */
  SetInitializeSettingUser(state, initialObject) {
    state.headerItems = initialObject.headerItems
    state.selectRole = initialObject.selectRole
    state.selectCenter = initialObject.selectCenter
    state.selectCenterSearch = initialObject.selectCenterSearch
    state.selectCenterIncharge = initialObject.selectCenterIncharge
  },
  /** 項目設定：受付情報インポート */
  SetInitializeArrivalImport(state, initialObject) {
    state.headerItems = initialObject.headerItems
  },
  /** 項目設定：受付情報エクスポート */
  SetInitializeArrivalExport(state, initialObject) {
    state.headerItems = initialObject.headerItems
    state.selectCenterSearch = initialObject.selectCenterSearch
  },
  /** ドロワーメニュー：ミニ状態 */
  ToggleMiniDrawer(state, value) {
    state.isMini = value
  },
  /** ローディング：状態変更 */
  ToggleIsLoading(state, value) {
    state.isLoading = value
  },
  /** 初期化 */
  ResetStore(state) {
    state.headerItems = []
    state.selectAuthorization = []
    state.selectRole = []
    state.selectCenter = []
    state.selectCenterIncharge = []
    state.selectDataCategory = []
    state.selectCategory = []
    state.selectPriority = []
    state.selectBerth = []
    state.selectWorkStatus = []
    state.selectNoticeCategory = []
    state.selectCenterSearch = []
  }
}
