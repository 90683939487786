export class Common {
  helperTitle = path => {
    let titleIcon
    let mainTitle
    let subTitle
    switch (path) {
      case '/arrival/add':
        titleIcon = 'mdi-account'
        mainTitle = '受付情報'
        subTitle = '登録'
        break
      case '/arrival/import':
        titleIcon = 'mdi-account'
        mainTitle = '受付情報'
        subTitle = 'インポート'
        break
      case '/arrival/export':
        titleIcon = 'mdi-account'
        mainTitle = '受付情報'
        subTitle = 'エクスポート'
        break
      case '/arrival/list':
        titleIcon = 'mdi-account'
        mainTitle = '受付情報'
        subTitle = '一覧'
        break
      case '/arrival/list/detail':
        titleIcon = 'mdi-account'
        mainTitle = '受付情報'
        subTitle = '表示'
        break
      case '/arrival/list/detail/edit':
        titleIcon = 'mdi-account'
        mainTitle = '受付情報'
        subTitle = '編集'
        break
      case '/arrival/search':
        titleIcon = 'mdi-account'
        mainTitle = '受付情報'
        subTitle = '他センター検索'
        break
      case '/arrival/search/detail':
        titleIcon = 'mdi-account'
        mainTitle = '受付情報'
        subTitle = '他センター情報'
        break
      case '/arrival/schedule':
        titleIcon = 'mdi-account'
        mainTitle = '入出荷情報'
        subTitle = '一覧'
        break
      case '/plan/list/edit':
        titleIcon = 'mdi-account'
        mainTitle = '配車情報'
        subTitle = '一括更新'
        break
      case '/setting/information':
        titleIcon = 'mdi-cogs'
        mainTitle = 'お知らせ管理'
        subTitle = ''
        break
      case '/setting/center':
        titleIcon = 'mdi-cogs'
        mainTitle = 'センター管理'
        subTitle = ''
        break
      case '/setting/berth':
        titleIcon = 'mdi-cogs'
        mainTitle = 'バース管理'
        subTitle = ''
        break
      case '/setting/role':
        titleIcon = 'mdi-cogs'
        mainTitle = '権限管理'
        subTitle = ''
        break
      case '/setting/user':
        titleIcon = 'mdi-cogs'
        mainTitle = 'ユーザー管理'
        subTitle = ''
        break
      default:
        titleIcon = ''
        mainTitle = ''
        subTitle = ''
        break
    }
    return {
      icon: titleIcon,
      title: String(`${mainTitle} ${subTitle}`)
    }
  }
}

export default ({ app }, inject) => {
  inject('Common', new Common())
}
