<template lang="pug">
  v-btn.custom-button(tile depressed color="error" @click="onClick")
    v-icon(left) mdi-close-circle-outline
    slot 削除済
</template>

<script>
export default {
  props: {},
  methods: {
    onClick() {
      this.$emit('clickRecover')
    }
  }
}
</script>

<style lang="sass" scoped>
.custom-button
  padding: 0 16px !important
</style>
