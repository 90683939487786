<template lang="pug">
  v-text-field.text-dash.pt-4(v-model="inputData" :label="formLabel" v-mask="'###'"
  :readonly="isReadOnly" :disabled="isDisable" :rules="rules" hide-details required)
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: () => '',
      required: false
    },
    formLabel: {
      type: String,
      required: true
    },
    isReadOnly: {
      type: Boolean,
      default: () => false,
      required: false
    },
    isDisable: {
      type: Boolean,
      default: () => false,
      required: false
    }
  },
  data: () => ({
    rules: []
  }),
  computed: {
    inputData: {
      get() {
        return this.value
      },
      set(changeValue) {
        if (this.value !== changeValue) this.$emit('input', changeValue)
      }
    }
  }
}
</script>
