const middleware = {}

middleware['all-incharge'] = require('../src/middleware/all-incharge.js')
middleware['all-incharge'] = middleware['all-incharge'].default || middleware['all-incharge']

middleware['authorization'] = require('../src/middleware/authorization.js')
middleware['authorization'] = middleware['authorization'].default || middleware['authorization']

middleware['no-authorization'] = require('../src/middleware/no-authorization.js')
middleware['no-authorization'] = middleware['no-authorization'].default || middleware['no-authorization']

export default middleware
