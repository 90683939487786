export default ({ app }, inject) => {
  inject('downloadFile', response => {
    // Header：取得
    const contentDisposition = response.headers['content-disposition']
    // ファイル名：デコード取得
    let fileName = contentDisposition.substring(contentDisposition.indexOf("''") + 2, contentDisposition.length)
    fileName = decodeURI(fileName).replace(/\+/g, ' ')
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  })
}
