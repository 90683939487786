<template lang="pug">
  v-btn.custom-button(tile depressed color="primary" @click="onClick" :disabled="disabled")
    v-icon(left) mdi-check
    slot 登録
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit('clickSave')
    }
  }
}
</script>

<style lang="sass" scoped>
.custom-button
  padding: 0 16px !important
</style>
