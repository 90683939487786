import Vue from 'vue'

/**
 * 定数
 * ※ライフサイクル的にPluginがbeforeCreate/computedよりも先のため
 */
Vue.prototype.$constant = {
  date_format: 'YYYY/MM/DD',
  auth: {
    login: 'login',
    header: 'header'
  }
}

/** メッセージ */
Vue.prototype.$message = {
  error_404: `指定されたページが見つかりませんでした。\n既に削除されたか、URLが間違っている可能性があります。`,
  no_count: `表示項目はありません。`
}
