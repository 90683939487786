import { render, staticRenderFns } from "./AButtonRecover.vue?vue&type=template&id=dd3de818&scoped=true&lang=pug&"
import script from "./AButtonRecover.vue?vue&type=script&lang=js&"
export * from "./AButtonRecover.vue?vue&type=script&lang=js&"
import style0 from "./AButtonRecover.vue?vue&type=style&index=0&id=dd3de818&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd3de818",
  null
  
)

export default component.exports