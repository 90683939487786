import Vue from 'vue'

/** Button */
import AButtonSave from '@/components/atoms/button/AButtonSave.vue'
import AButtonEdit from '@/components/atoms/button/AButtonEdit.vue'
import AButtonCancel from '@/components/atoms/button/AButtonCancel.vue'
import AButtonArrivalStatus from '@/components/atoms/button/AButtonArrivalStatus.vue'
import AButtonReload from '@/components/atoms/button/AButtonReload.vue'
import AButtonSearch from '@/components/atoms/button/AButtonSearch.vue'
import AButtonTrack from '@/components/atoms/button/AButtonTrack.vue'
import AButtonAdd from '@/components/atoms/button/AButtonAdd.vue'
import AButtonBack from '@/components/atoms/button/AButtonBack.vue'
import AButtonDelete from '@/components/atoms/button/AButtonDelete.vue'
import AButtonRecover from '@/components/atoms/button/AButtonRecover.vue'
import AButtonMail from '@/components/atoms/button/AButtonMail.vue'
/** Item */
import AItemNameToIsDisplay from '@/components/atoms/Item/AItemNameToIsDisplay.vue'
/** Select */
import ASelectBox from '@/components/atoms/select/ASelectBox.vue'
import ASelectMultiBox from '@/components/atoms/select/ASelectMultiBox.vue'
/** Text */
import ATextArea from '@/components/atoms/text/ATextArea.vue'
import ATextField from '@/components/atoms/text/ATextField.vue'
import ATextFieldMail from '@/components/atoms/text/ATextFieldMail.vue'
import ATextFieldTelephone from '@/components/atoms/text/ATextFieldTelephone.vue'
import ATextFieldPassword from '@/components/atoms/text/ATextFieldPassword.vue'
import ATextFieldVehicleNo from '@/components/atoms/text/ATextFieldVehicleNo.vue'
import ATextFieldShipNo from '@/components/atoms/text/ATextFieldShipNo.vue'
import ATextFieldDestination from '@/components/atoms/text/ATextFieldDestination.vue'
import ATextFieldJustifyRight from '@/components/atoms/text/ATextFieldJustifyRight.vue'
import ATextFieldJustifyCenter from '@/components/atoms/text/ATextFieldJustifyCenter.vue'
import ATextFieldRules from '@/components/atoms/text/rules/ATextFieldRules.vue'
import ATextFieldDisplayOrder from '@/components/atoms/text/rules/ATextFieldDisplayOrder.vue'
/** Checkbox */
import ACheckbox from '@/components/atoms/checkbox/ACheckbox.vue'
/** ToolTip */
import ATooltip from '@/components/atoms/tooltip/ATooltip.vue'
/** Date */
import ADateText from '@/components/atoms/date/ADateText.vue'
/** Icon */
import AIconPickup from '@/components/atoms/icon/AIconPickup.vue'
import AIconDelivery from '@/components/atoms/icon/AIconDelivery.vue'
import AIconPriority from '@/components/atoms/icon/AIconPriority.vue'
import AIconTotalCount from '@/components/atoms/icon/AIconTotalCount.vue'

Vue.mixin({
  components: {
    AButtonSave,
    AButtonEdit,
    AButtonCancel,
    AButtonArrivalStatus,
    AButtonReload,
    AButtonSearch,
    AButtonTrack,
    AButtonAdd,
    AButtonDelete,
    AButtonBack,
    AButtonRecover,
    AButtonMail,
    AItemNameToIsDisplay,
    ASelectBox,
    ASelectMultiBox,
    ATextArea,
    ATextField,
    ATextFieldMail,
    ATextFieldTelephone,
    ATextFieldPassword,
    ATextFieldVehicleNo,
    ATextFieldShipNo,
    ATextFieldDestination,
    ATextFieldJustifyRight,
    ATextFieldJustifyCenter,
    ATextFieldRules,
    ATextFieldDisplayOrder,
    ACheckbox,
    ATooltip,
    ADateText,
    AIconPickup,
    AIconDelivery,
    AIconPriority,
    AIconTotalCount
  }
})
