import createPersistedState from 'vuex-persistedstate'
import { auth } from '@/common/store-function'
const contextLogin = '/login'
const contextLogout = '/logout'
const contextAuthPath = 'v1/auth/setting'

/** STORE STATE */
export const state = () => ({
  loginUser: {
    name: String,
    roleId: Number,
    centerInchargeId: Number,
    centerId: Number,
    centerName: String
  },
  menuInfo: {
    sideMenu: [
      {
        id: Number,
        title: String,
        icon: String,
        menu: [
          {
            linkName: String,
            toContext: String
          }
        ]
      }
    ]
  },
  // middlewareでの判定のため、初期値設定
  token: null
})

/** GETTERS */
export const getters = {
  loginUser: state => state.loginUser,
  menuInfo: state => state.menuInfo
}

/** ACTIONS */
export const actions = {
  /** サーバーサイド処理（※SPAの場合、フロント処理のみしか行われない。） */
  nuxtServerInit({ commit }, { req }) {
    // 設定：CSRFトークン
    if (req.cookies) {
      commit(auth.COMMIT.SET_TOKEN, req.token())
    }
    if (req.session && req.session.loginUser) {
      commit(auth.COMMIT.SET_USER, req.session.loginUser)
    }
  },
  /** 永続化（ブラウザ更新時にもVuexが初期化されないための対応） */
  nuxtClientInit({ commit, state, dispatch }, { req }) {
    createPersistedState({
      // 保存先をSessionStrageにしているため、別タブ起動などで切断される。
      storage: window.sessionStorage,
      key: process.env.NUXT_ENV_AUTH_SESSION_NAME,
      paths: [process.env.NUXT_ENV_AUTH]
    })(this)
  },
  /** POST：ログイン */
  async login({ commit, dispatch }, { loginCode, password }) {
    const params = new URLSearchParams()
    params.append('loginCode', loginCode)
    params.append('password', password)
    await this.$axios.$post(`${contextLogin}`, params).then(response => {
      commit(auth.COMMIT.SET_TOKEN, response.token)
      commit(auth.COMMIT.SET_LOGIN_USER, response)
      commit(auth.COMMIT.SET_MENU_INFO, response)
      dispatch(auth.ACTIONS.STORE_INITIALIZE)
    })
  },
  /** POST：ログアウト */
  async logout({ commit }) {
    await this.$axios.$post(`${contextLogout}`).then(() => {
      window.sessionStorage.clear()
      commit(auth.COMMIT.SET_TOKEN, null)
      commit(auth.COMMIT.SET_LOGIN_USER, {})
    })
  },
  /** PUT：centerIdの設定 */
  async putAuthInfo({ commit }, info) {
    await this.$axios.$put(`${contextAuthPath}`, info).then(response => {
      commit(auth.COMMIT.SET_LOGIN_USER, response)
    })
  },
  /** storeの初期化 */
  storeInitialize() {
    for (const key in this._actions) {
      if (key.match('^.*/initialize$')) this.dispatch(key)
    }
  }
}

/** MUTATIONS */
export const mutations = {
  /** 設定：ログインユーザー情報 */
  SetLoginUser(state, user) {
    state.loginUser.name = user.name
    state.loginUser.roleId = user.roleId
    state.loginUser.centerInchargeId = user.centerInchargeId
    state.loginUser.centerId = user.centerId
    state.loginUser.centerName = user.centerName
  },
  /** 設定：サイドメニュー情報 */
  SetMenuInfo(state, info) {
    state.menuInfo = info.menuInfo
  },
  /** 設定：トークン */
  SetToken(state, token) {
    state.token = token
  }
}
