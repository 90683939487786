// paramsSerializer利用（依存関係：express@）
import qs from 'qs'
import moment from 'moment'
import { arrival } from '@/common/store-function'
const contextPath = '/v1/arrival'

/** STORE STATE */
export const state = () => ({
  /** STORE：受付情報 登録・編集情報 */
  editInfo: {
    workDate: Date,
    shipNo: String,
    dataCategoryId: Number,
    categoryId: Number,
    pickupName: String,
    deliveryName: String,
    note: String,
    arrivedAt: String,
    guidedLastAt: String,
    workedStartAt: String,
    workedEndAt: String,
    isDelete: Boolean,
    updatedAt: String,
    // WorkStatusInfo:start
    berthId: Number,
    priorityId: Number,
    workStatusId: Number,
    // WorkStatusInfo:end
    planInfo: {
      shippingName: String,
      vehicleType: String,
      vehicleNo: String,
      driverName: String,
      driverTel: String,
      driverMail: String,
      updatedAt: String
    }
  },
  /** STORE：受付情報 */
  arrivalInfo: {
    id: Number,
    centerName: String,
    workDate: Date,
    shipNo: String,
    dataCategoryId: Number,
    dataCategoryName: String,
    categoryId: Number,
    categoryName: String,
    pickupName: String,
    deliveryName: String,
    note: String,
    arrivedAt: String,
    guidedLastAt: String,
    workedStartAt: String,
    workedEndAt: String,
    isDelete: Boolean,
    updatedAt: String,
    // WorkStatusInfo:start
    berthId: Number,
    berthName: String,
    priorityId: Number,
    priorityName: String,
    workStatusId: Number,
    workStatusName: String,
    isBeforeWorkEnd: Boolean,
    // WorkStatusInfo:end
    planInfo: {
      shippingName: String,
      vehicleType: String,
      vehicleNo: String,
      driverName: String,
      driverTel: String,
      driverMail: String,
      updatedAt: String
    },
    itemInfoList: [
      {
        id: Number,
        name: String,
        code: String,
        quantity: Number,
        productionDate: Date,
        sledDate: Date
      }
    ]
  },
  /** STORE：受付情報 一覧 */
  arrivalInfoList: [],
  /** STORE: 受付情報 予定車両情報一覧 */
  scheduledInfoList: [],
  /** STORE:プルダウンリスト選択項目 */
  selectCategory: {
    dataCategory: [],
    category: []
  },
  /** STORE:プルダウンリスト選択項目 */
  selectArrivalStatus: {
    priority: [],
    berth: [],
    status: []
  },
  /** STORE:受付情報 インポート一覧 */
  importList: [],
  /** STORE:受付情報 エクスポート一覧 */
  exportList: [],
  /** STORE:受付情報 検索条件 */
  searchConditionForInfoList: null,
  /** STORE:受付情報 検索条件 */
  searchCondition: null
})

/** GETTERS */
export const getters = {
  editInfo: state => state.editInfo,
  arrivalInfo: state => state.arrivalInfo,
  arrivalItemList: state => state.arrivalInfo.itemInfoList,
  arrivalInfoList: state => state.arrivalInfoList,
  scheduledInfoList: state => state.scheduledInfoList,
  selectCategory: state => state.selectCategory,
  selectArrivalStatus: state => state.selectArrivalStatus,
  selectArrivalStatusName: state => value => state.selectArrivalStatus.status.find(status => status.value === value),
  importList: state => state.importList,
  exportList: state => state.exportList,
  searchConditionForInfoList: state => state.searchConditionForInfoList,
  searchCondition: state => state.searchCondition
}

/** ACTIONS */
export const actions = {
  /** 初期化 */
  async initialize({ commit }) {
    await commit('ResetStore')
  },
  /** RESET：Vuex初期化 */
  resetInfo({ commit }) {
    commit(arrival.COMMIT.RESET_INFO)
  },
  resetScheduleInfoList({ commit }) {
    commit(arrival.COMMIT.RESET_SCHEDULE_INFO_LIST)
  },
  /** GET:受付情報 一件取得 */
  async getInfoById({ commit }, info) {
    await this.$axios.$get(`${contextPath}/${info.arrivalId}`).then(response => {
      commit(arrival.COMMIT.SET_INFO, response)
    })
  },
  /** FETCH：受付情報 1件取得 */
  async fetchInfo({ commit }, info) {
    await this.$axios.$get(`${contextPath}/${info.id}`).then(response => {
      commit(arrival.COMMIT.SET_INFO, response)
    })
  },
  /** POST：受付情報 新規登録 */
  async postInfo({ commit, state }, info) {
    // 登録失敗を考え、一旦Vuexにコミット
    commit(arrival.COMMIT.SET_EDIT_INFO, info)
    await this.$axios.$post(contextPath, state.editInfo)
  },
  /** PUT：受付情報 1件更新 */
  async putInfoById({ commit, state }, info) {
    // 登録失敗を考え、一旦Vuexにコミット
    commit(arrival.COMMIT.SET_EDIT_INFO, info)
    await this.$axios.$put(`${contextPath}/${state.arrivalInfo.id}`, state.editInfo)
  },
  /** DELETE：受付情報 1件削除 */
  async deleteInfoById({ commit, state }, info) {
    await this.$axios.$delete(`${contextPath}/${state.arrivalInfo.id}`, { data: info })
  },
  /** GET：積地／卸地名称 条件取得 */
  async getDestinationList({ commit }, info) {
    const result = await this.$axios.$get(`${contextPath}/destination`, { params: info })
    return result
  },
  /** PUT：作業状況情報 1件更新 */
  async putWorkStatusInfoById({ commit, state }, info) {
    commit(arrival.COMMIT.SET_WORK_STATUS_INFO, info)
    await this.$axios.$put(`${contextPath}/workstatus/${state.arrivalInfo.id}`, info)
  },
  /** FETCH：受付情報 一覧 条件取得 */
  async fetchInfoList({ commit }, info) {
    // stateには条件をそのまま持たせたいのでここでmergeObjectに変換
    const mergeObject = {
      ...info,
      ...{ dataCategoryId: info.dataCategoryId.value },
      ...{ categoryId: info.categoryId.value },
      ...{ berthId: info.berthId.value },
      ...{ priorityId: info.priorityId.value },
      ...{
        planInfo: {
          vehicleType: info.planInfo.vehicleType,
          vehicleNo: info.planInfo.vehicleNo.replace(/-/g, '')
        }
      },
      workStatusIdList: info.workStatusIdList.map(item => item.value)
    }

    await this.$axios
      .$get(`${contextPath}/infolist`, {
        params: mergeObject,
        paramsSerializer: params => {
          return qs.stringify(params, { allowDots: true })
        }
      })
      .then(response => {
        commit('SetSearchConditionForInfoList', info)
        commit(arrival.COMMIT.SET_INFO_LIST, response)
      })
  },
  /** FETCH：受付情報 他センター情報一覧 条件取得 */
  async fetchSearch({ commit }, info) {
    // stateには条件をそのまま持たせたいのでここでmergeObjectに変換
    const mergeObject = {
      ...info,
      ...{ dataCategoryId: info.dataCategoryId.value },
      ...{ categoryId: info.categoryId.value },
      ...{
        planInfo: {
          vehicleType: info.planInfo.vehicleType,
          vehicleNo: info.planInfo.vehicleNo.replace(/-/g, '')
        }
      },
      workStatusIdList: info.workStatusIdList.map(item => item.value),
      centerIdList: info.centerIdList.map(item => item.value)
    }

    await this.$axios
      .$get(`${contextPath}/search`, {
        params: mergeObject,
        paramsSerializer: params => {
          return qs.stringify(params, { allowDots: true })
        }
      })
      .then(response => {
        commit('SetSearchCondition', info)
        commit(arrival.COMMIT.SET_INFO_LIST, response)
      })
  },
  /** GET：受付情報 予定車両数 一覧取得 */
  async getByWorkingDay({ commit }, info) {
    await this.$axios.$get(`${contextPath}/schedule/count`, { params: info }).then(response => {
      commit(arrival.COMMIT.SET_SCHEDULED_INFO_LIST, response)
    })
  },
  /** FETCH：受付情報 インポート 一覧（履歴）取得 */
  async fetchImportList({ commit }) {
    await this.$axios.$get(`${contextPath}/import`).then(response => {
      commit(arrival.COMMIT.SET_IMPORT_LIST, response)
    })
  },
  /** UPLOAD：受付情報 インポートファイル 送信 */
  async uploadImportFile({ dispatch }, info) {
    const momentDate = moment(info.workDate)
    const params = new URLSearchParams()
    params.append('year', momentDate.year())
    // moment.jsは「1月=0」
    params.append('month', momentDate.month() + 1)
    params.append('day', momentDate.date())
    const config = { headers: { 'content-type': 'multipart/form-data' } }
    const formData = new FormData()
    if (info.importfile) formData.append('uploadFile', info.importfile)
    await this.$axios
      .$post(`${contextPath}/import/${info.selectData}/${info.selectReceipt}?${params.toString()}`, formData, config)
      .then(() => {
        // 履歴（一覧）更新
        dispatch(arrival.ACTIONS.FETCH_IMPORT_LIST)
      })
  },
  /** DOWNLOAD：受付情報 インポート インポートファイル取得 */
  async downloadImportFile({ commit }, info) {
    // ※ラッパーの$getではHeaderが取得できないため、「$axios.get」を利用する。
    await this.$axios.get(`${contextPath}/import/${info.id}/importfile/`, { responseType: 'blob' }).then(response => {
      this.$downloadFile(response)
    })
  },
  /** DOWNLOAD：受付情報 インポート エラーログファイル取得  */
  async downloadImportErrorFile({ commit }, info) {
    // ※ラッパーの$getではHeaderが取得できないため、「$axios.get」を利用する。
    await this.$axios.get(`${contextPath}/import/${info.id}/errorfile/`, { responseType: 'blob' }).then(response => {
      this.$downloadFile(response)
    })
  },
  /** FETCH：受付情報 エクスポート 一覧（履歴）取得 */
  async fetchExportList({ commit }) {
    await this.$axios.$get(`${contextPath}/export`).then(response => {
      commit(arrival.COMMIT.SET_EXPORT_LIST, response)
    })
  },
  /** DOWNLOAD：受付情報 エクスポート エクスポートファイル取得 */
  async downloadExportFile({ dispatch }, info) {
    // ※ラッパーの$getではHeaderが取得できないため、「$axios.get」を利用する。
    await this.$axios
      .get(`${contextPath}/export/file`, { responseType: 'blob', params: info })
      .then(response => {
        this.$downloadFile(response)
      })
      .finally(() => {
        // 履歴（一覧）更新
        dispatch(arrival.ACTIONS.FETCH_EXPORT_LIST)
      })
  }
}

/** MUTATIONS */
export const mutations = {
  /** 設定：受付情報 登録・編集情報 */
  SetEditInfo(state, info) {
    state.editInfo.workDate = 'workDate' in info ? info.workDate : null
    // ShipNoを空更新することは不可のため、nullを設定
    state.editInfo.shipNo = 'shipNo' in info ? (info.shipNo === '' ? null : info.shipNo) : null
    state.editInfo.dataCategoryId = 'dataCategoryId' in info ? info.dataCategoryId : null
    state.editInfo.categoryId = 'categoryId' in info ? info.categoryId : null
    state.editInfo.pickupName = 'pickupName' in info ? info.pickupName : null
    state.editInfo.deliveryName = 'deliveryName' in info ? info.deliveryName : null
    state.editInfo.note = 'note' in info ? info.note : null
    state.editInfo.isDelete = 'isDelete' in info ? info.isDelete : null
    state.editInfo.updatedAt = 'updatedAt' in info ? info.updatedAt : null
    state.editInfo.berthId = 'berthId' in info ? info.berthId : null
    state.editInfo.priorityId = 'priorityId' in info ? info.priorityId : null
    state.editInfo.workStatusId = 'workStatusId' in info ? info.workStatusId : null
    if ('planInfo' in info) {
      state.editInfo.planInfo.shippingName = 'shippingName' in info.planInfo ? info.planInfo.shippingName : null
      state.editInfo.planInfo.vehicleType = 'vehicleType' in info.planInfo ? info.planInfo.vehicleType : null
      state.editInfo.planInfo.vehicleNo =
        'vehicleNo' in info.planInfo
          ? info.planInfo.vehicleNo !== null
            ? info.planInfo.vehicleNo.replace(/-/g, '')
            : null
          : null
      state.editInfo.planInfo.driverName = 'driverName' in info.planInfo ? info.planInfo.driverName : null
      state.editInfo.planInfo.driverTel =
        'driverTel' in info.planInfo
          ? info.planInfo.driverTel !== null
            ? info.planInfo.driverTel.replace(/-/g, '')
            : null
          : null
      state.editInfo.planInfo.driverMail = 'driverMail' in info.planInfo ? info.planInfo.driverMail : null
      state.editInfo.planInfo.updatedAt = 'updatedAt' in info.planInfo ? info.planInfo.updatedAt : null
    }
  },
  /** 設定：受付情報 初期化 */
  ResetInfo(state) {
    Object.keys(state.arrivalInfo).map(n1 => {
      if (n1 === 'isDelete') {
        state.arrivalInfo[name] = false
      } else if (n1 === 'itemInfoList') {
        state.arrivalInfo[name] = []
      } else if (n1 === 'planInfo') {
        Object.keys(state.arrivalInfo[n1]).map(n2 => (state.arrivalInfo[n1][n2] = ''))
      } else if (n1 !== '') {
        state.arrivalInfo[n1] = ''
      }
    })
  },
  /** 設定：受付情報 予定車両情報一覧 初期化 */
  ResetScheduleInfoList(state) {
    state.scheduledInfoList = []
  },
  /** 設定：受付情報 1件（GET） */
  SetInfo(state, info) {
    state.arrivalInfo.id = info.id
    state.arrivalInfo.centerName = info.centerName
    state.arrivalInfo.workDate = info.workDate
    state.arrivalInfo.shipNo = info.shipNo
    state.arrivalInfo.dataCategoryId = info.dataCategoryId
    state.arrivalInfo.dataCategoryName = info.dataCategoryName
    state.arrivalInfo.categoryId = info.categoryId
    state.arrivalInfo.categoryName = info.categoryName
    state.arrivalInfo.pickupName = info.pickupName
    state.arrivalInfo.deliveryName = info.deliveryName
    state.arrivalInfo.note = info.note
    state.arrivalInfo.arrivedAt = info.arrivedAt
    state.arrivalInfo.guidedLastAt = info.guidedLastAt
    state.arrivalInfo.workedStartAt = info.workedStartAt
    state.arrivalInfo.workedEndAt = info.workedEndAt
    state.arrivalInfo.isDelete = info.isDelete
    state.arrivalInfo.updatedAt = info.updatedAt
    state.arrivalInfo.berthId = info.berthId
    state.arrivalInfo.berthName = info.berthName
    state.arrivalInfo.priorityId = info.priorityId
    state.arrivalInfo.priorityName = info.priorityName
    state.arrivalInfo.workStatusId = info.workStatusId
    state.arrivalInfo.workStatusName = info.workStatusName
    if (info.planInfo) {
      state.arrivalInfo.planInfo.shippingName = info.planInfo.shippingName
      state.arrivalInfo.planInfo.vehicleType = info.planInfo.vehicleType
      state.arrivalInfo.planInfo.vehicleNo = info.planInfo.vehicleNo
      state.arrivalInfo.planInfo.driverName = info.planInfo.driverName
      state.arrivalInfo.planInfo.driverTel = info.planInfo.driverTel
      state.arrivalInfo.planInfo.driverMail = info.planInfo.driverMail
      state.arrivalInfo.planInfo.updatedAt = info.planInfo.updatedAt
    } else {
      state.arrivalInfo.planInfo.shippingName = ''
      state.arrivalInfo.planInfo.vehicleType = ''
      state.arrivalInfo.planInfo.vehicleNo = ''
      state.arrivalInfo.planInfo.driverName = ''
      state.arrivalInfo.planInfo.driverTel = ''
      state.arrivalInfo.planInfo.driverMail = ''
      state.arrivalInfo.planInfo.updatedAt = ''
    }
    state.arrivalInfo.itemInfoList = info.itemInfoList
  },
  /** 設定：受付情報 作業状況情報 1件 */
  SetWorkStatusInfo(state, info) {
    state.arrivalInfo.berthId = info.berthId
    state.arrivalInfo.priorityId = info.priorityId
    state.arrivalInfo.workStatusId = info.workStatusId
    state.arrivalInfo.updatedAt = info.updatedAt
  },
  /** 設定：受付情報 条件取得 リスト */
  SetInfoList(state, items) {
    state.arrivalInfoList = items
  },
  /** 変換：受付情報 予定車両一覧 */
  SetScheduledInfoList(state, items) {
    state.scheduledInfoList = items
  },
  /** 設定：受付情報 インポート一覧 */
  SetImportList(state, items) {
    state.importList = items
  },
  /** 設定：受付情報 エクスポート一覧 */
  SetExportList(state, items) {
    state.exportList = items
  },
  SetSearchConditionForInfoList(state, info) {
    state.searchConditionForInfoList = info
  },
  SetSearchCondition(state, info) {
    state.searchCondition = info
  },
  /** 初期化 */
  ResetStore(state) {
    Object.keys(state.arrivalInfo).map(n1 => {
      if (n1 === 'isDelete') {
        state.arrivalInfo[name] = false
      } else if (n1 === 'itemInfoList') {
        state.arrivalInfo[name] = []
      } else if (n1 === 'planInfo') {
        Object.keys(state.arrivalInfo[n1]).map(n2 => (state.arrivalInfo[n1][n2] = ''))
      } else if (n1 !== '') {
        state.arrivalInfo[n1] = ''
      }
    })
    state.editInfo = {
      planInfo: {}
    }
    state.arrivalInfoList = []
    state.scheduledInfoList = []
    state.importList = []
    state.exportList = []
    state.searchConditionForInfoList = null
    state.searchCondition = null
  }
}
