import { settingBerth } from '@/common/store-function'
const contextPath = '/v1/setting/berth'

/** STORE STATE */
export const state = () => ({
  berthInfo: {
    centerId: Number,
    berthId: Number,
    name: String,
    note: String,
    isDisplay: Boolean,
    displayOrder: Number,
    updatedAt: String
  },
  berthList: [],
  searchInfo: {
    centerId: 0
  }
})

/** GETTERS */
export const getters = {
  // バース情報 1件
  berthInfo: state => state.berthInfo,
  // バース情報リスト
  berthList: state => state.berthList
}

/** ACTIONS */
export const actions = {
  /** 初期化 */
  async initialize({ commit }) {
    await commit('ResetStore')
  },
  /** FETCH：バース情報 一覧取得 */
  async fetchList({ dispatch, commit }) {
    commit(settingBerth.COMMIT.SET_SEARCH_INFO, { centerId: 0 })
    await dispatch(settingBerth.ACTIONS.FETCH_SEARCH)
  },
  /** FETCH：バース情報 検索 */
  async fetchSearch({ commit, state }) {
    await this.$axios.$get(`${contextPath}/search`, { params: state.searchInfo }).then(response => {
      commit(settingBerth.COMMIT.SET_LIST, response)
    })
  },
  /** FETCH：バース情報 検索（条件変更） */
  async fetchSearchChange({ dispatch, commit }, info) {
    commit(settingBerth.COMMIT.SET_SEARCH_INFO, info)
    await dispatch(settingBerth.ACTIONS.FETCH_SEARCH)
  },
  /** FETCH：バース情報 1件取得（※詳細表示の際に再取得処理として利用） */
  fetchInfo({ commit }, info) {
    commit(settingBerth.COMMIT.SET_INFO, info)
  },
  /** GET：バース情報 1件取得 */
  async getInfoById({ commit }, info) {
    const result = await this.$axios.$get(`${contextPath}/${info.centerId}/${info.berthId}`)
    return result
  },
  /** POST：バース情報 新規登録 */
  async postInfo({ dispatch, commit, state }, info) {
    // 登録失敗を考え、一旦Vuexにコミット
    commit(settingBerth.COMMIT.SET_INFO, info)
    await this.$axios.$post(contextPath, state.berthInfo).then(() => {
      dispatch(settingBerth.ACTIONS.FETCH_SEARCH)
    })
  },
  /** PUT：バース情報 1件更新 */
  async putInfoById({ dispatch, commit, state }, info) {
    // 登録失敗を考え、一旦Vuexにコミット
    commit(settingBerth.COMMIT.SET_INFO, info)
    await this.$axios.$put(`${contextPath}/${info.centerId}/${state.berthInfo.berthId}`, state.berthInfo).then(() => {
      dispatch(settingBerth.ACTIONS.FETCH_SEARCH)
    })
  }
}

/** MUTATIONS */
export const mutations = {
  /** 設定：バース情報 1件 */
  SetInfo(state, info) {
    state.berthInfo.centerId = info.centerId
    state.berthInfo.berthId = info.berthId
    state.berthInfo.name = info.name
    state.berthInfo.note = info.note
    state.berthInfo.isDisplay = info.isDisplay
    state.berthInfo.displayOrder = info.displayOrder
    state.berthInfo.updatedAt = info.updatedAt
  },
  /** 設定：バース情報リスト */
  SetList(state, items) {
    state.berthList = items
  },
  /** 設定：バース検索条件情報 */
  SetSearchInfo(state, items) {
    state.searchInfo.centerId = items.centerId
  },
  /** 初期化 */
  ResetStore(state) {
    state.berthInfo = {}
    state.berthList = []
    state.searchInfo = {
      centerId: 0
    }
  }
}
